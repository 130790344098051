import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Button } from 'react-bootstrap';

const schema = yup.object({
  id: yup.string().required(),
  name: yup.string().required(),
});

const AddCategoryForm = ({
  edit,
  initialValues,
  onSubmit,
}) => (
  <Formik
    schema={schema}
    validationSchema={schema}
    onSubmit={onSubmit}
    initialValues={initialValues}
  >
    {
      ({
        values,
        handleChange,
        handleSubmit,
        touched,
        errors,
        isValid,
        handleBlur,
        submitCount,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId='id'>
            <Form.Label>Id</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter id'
              name='id'
              value={values.id}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.id && !errors.id}
              isInvalid={submitCount && !!errors.id}
              readOnly={edit}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.id}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter name'
              name='name'
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.name && !errors.name}
              isInvalid={submitCount && !!errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          {!edit && (
            <Button disabled={isSubmitting} variant='primary' type='submit'>
              Add
            </Button>
          )}
          {edit && (
            <Button disabled={isSubmitting} variant='primary' type='submit'>
              Save
            </Button>
          )}
        </Form>
      )
    }
  </Formik>
);

AddCategoryForm.propTypes = {
  initialValues: PropTypes.shape({}),
  edit: PropTypes.bool,
};

AddCategoryForm.defaultProps = {
  initialValues: {},
  edit: false,
};

export default AddCategoryForm;
