import React, { useState } from 'react';
import * as R from 'ramda';
import { FieldArray, connect } from 'formik';
import { Form, ListGroup, ButtonGroup, Button } from 'react-bootstrap';
import { DndProvider, useDrop, useDrag } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend'
import update from 'immutability-helper';
import { NEW_EMAIL } from './constants';
import { ProcessButton } from '../../Buttons';
import ItemTypes from './ItemTypes';
import DeleteModal from '../../DeleteModal';
import useModal from '../../../hooks/useModal';

const DndIcon = () => (
  <div className='add-emails-form__email-list-dnd'>
    <i class='fas fa-ellipsis-v'></i>
    <i class='fas fa-ellipsis-v'></i>
  </div>
);

const ListItem = ({
  item,
  idx,
  formik,
  arrayHelpers,
  onDelete,
  openModal,
  moveItem,
  findItem,
}) => {
  const [isProcess, setIsProcess] = useState();
  const onDeleteHandler = async () => {
    try {
      setIsProcess(true)
      await onDelete(item, idx, formik);
    } finally {
      setIsProcess(false);
    }
  };

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.EMAIL, id: item.id, originalIndex: idx },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const [, drop] = useDrop({
    accept: ItemTypes.EMAIL,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== item.id) {
        const { index: overIndex, item: foundItem } = findItem(item.id);
        moveItem(item, draggedId, overIndex);
      }
    },
  });

  const draggingClass = isDragging ? ' add-emails-form__email-list--dragging' : '';

  return (
    <ListGroup.Item
      key={idx}
      className={`add-emails-form__email-list${draggingClass}`}
      ref={node => drag(drop(node))}
    >
      <DndIcon />
      <h2>{item.subject}</h2>
      <ButtonGroup aria-label='Examples group'>
        <ProcessButton variant='danger' isProcess={isProcess} onClick={onDeleteHandler}>
          <i className='fas fa-trash-alt'></i>
        </ProcessButton>
        <Button onClick={() => openModal(idx)}>
          <i className='fas fa-edit'></i>
        </Button>
      </ButtonGroup>
    </ListGroup.Item>
  );
};

const List = ({
  formik,
  arrayHelpers,
  onDelete,
  openModal,
}) => {
  const [, drop] = useDrop({ accept: ItemTypes.EMAIL });
  const [isDeleteModal, , closeDeleteModal, setDeleteModal] = useModal();
  const [isProcess, setIsProcess] = useState();

  const findItem = id => {
    const items = formik.values.script_ids;
    const index = R.findIndex(c => c.id === id, items);

    return {
      item: items[index],
      index,
    };
  };

  const moveItem = (_, id, atIndex) => {
    const { item, index } = findItem(id);
    formik.setFieldValue('script_ids', update(formik.values.script_ids, {
      $splice: [[index, 1], [atIndex, 0, item]],
    }));
  };

  const onDeleteHandler = async () => {
    try {
      setIsProcess(true)
      await onDelete(isDeleteModal[0], isDeleteModal[1], formik);
      arrayHelpers.remove(isDeleteModal[1])
    } finally {
      closeDeleteModal();
      setIsProcess(false);
    }
  };

  const openDeleteModal = (item, idx, formik) => {
    try {
      setDeleteModal([item, idx]);
      // setIsProcess(true)
      // await onDelete(item, idx, formik);
      // arrayHelpers.remove(idx)
    } finally {
      // setIsProcess(false);
    }
  };

  return (
    <>
      <ListGroup ref={drop}>
        {R.is(Array, formik.values.script_ids) && formik.values.script_ids.map((item, idx) => (
          <ListItem
            key={item.id}
            item={item}
            idx={idx}
            formik={formik}
            arrayHelpers={arrayHelpers}
            onDelete={openDeleteModal}
            openModal={openModal}
            moveItem={moveItem}
            findItem={findItem}
          />
        ))}
      </ListGroup>
      <DeleteModal
        title='Delete email'
        show={isDeleteModal}
        onHide={closeDeleteModal}
        onDelete={onDeleteHandler}
        isProcess={isProcess}
      />
    </>
  )
}

const EmailList = ({
  formik,
  openModal,
  onDelete,
}) => {
  return (
    <FieldArray
      name='script_ids'
      render={arrayHelpers => (
        <DndProvider backend={HTML5Backend}>
          <Form.Group>
            <List
              formik={formik}
              arrayHelpers={arrayHelpers}
              onDelete={onDelete}
              openModal={openModal}
            />
            <Form.Control
                type='hidden'
                isValid={formik.touched.script_ids && !formik.errors.script_ids}
                isInvalid={formik.submitCount && !!formik.errors.script_ids}
              />
            <Form.Control.Feedback type='invalid'>
              {formik.errors.script_ids}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <div className='text-right'>
              <ButtonGroup aria-label='Add Email'>
                <Button variant='success' onClick={() => {
                  openModal(NEW_EMAIL);
                }}>
                  Add Email <i className='fas fa-plus-square'></i>
                </Button>
              </ButtonGroup>
            </div>
          </Form.Group>
        </DndProvider>
      )}
    />
  )
};

export default connect(EmailList);
