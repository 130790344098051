import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Spinner } from 'react-bootstrap';
import applySelectors from '../utils/applySelectors';
import questionsSelectors from '../store/questions/selectors';
import questionsActions from '../store/questions/actions';
import selectors from '../store/emails/selectors';
import actions from '../store/emails/actions';
import { AddForm } from '../components/Emails';
import AddQuestionModalContainer from './AddQuestionModalContainer';
import useModal from '../hooks/useModal';

const EditEmailContainer = ({
  questionsList,
  questions,
  getQuestions,
  getSequences,
  editSequence,
  getEmail,
  addEmail,
  editEmail,
  deleteEmail,
  initialValues,
  uploadEmailImage,
  history,
  pagination,
  setSequencePage,
}) => {
  const onBackHandler = () => {
    setSequencePage(0);
    history.push('/emails');
  };

  useEffect(() => {
    getQuestions();
    getSequences({ cursor: pagination.currentCursor });
  }, [getQuestions, getSequences]);

  useEffect(() => {
    if (initialValues === false) {
      onBackHandler();
    }
  }, [initialValues]);

  const [isQuestionModal, openQuestionModal, hideQuestionModal] = useModal();

  if (!questions || !initialValues) {
    return <Spinner />;
  }

  const onSubmitHandler = async (data) => {
    try {
      await editSequence(data);
    } catch (error) {
    }
  };

  return (
    <>
      <AddQuestionModalContainer show={isQuestionModal} onHide={hideQuestionModal} />
      <AddForm
        questionsList={questionsList}
        questions={questions}
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        getEmail={getEmail}
        addEmail={addEmail}
        editEmail={editEmail}
        deleteEmail={deleteEmail}
        openQuestion={openQuestionModal}
        uploadEmailImage={uploadEmailImage}
        onBack={onBackHandler}
        edit
      />
    </>
  );
};

const mapStateToProps = applySelectors({
  questionsList: questionsSelectors.selectQuestions,
  questions: questionsSelectors.selectList,
  initialValues: selectors.selectSequence,
  pagination: selectors.selectPagination,
});

export default R.pipe(
  connect(
    mapStateToProps,
    {
      ...actions,
      getQuestions: questionsActions.getQuestions,
    }
  ),
  withRouter,
)(EditEmailContainer);
