import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import AddCategoryFormContainer from './AddCategoryFormContainer';
import actions from '../store/categories/actions';
import Head from '../components/Head/Head';

const AddCategoryContainer = ({
  history,
}) => {
  const afterSubmitHandler = () => {
    history.push('/categories');
  };
  return (
    <>
      <Head title='Add category' />
      <AddCategoryFormContainer
        afterSubmit={afterSubmitHandler}
      />
    </>
  );
};

export default R.pipe(
  connect(null, actions),
  withRouter,
)(AddCategoryContainer);
