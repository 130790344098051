import * as R from 'ramda';
import reducer from './reducer';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import schemas from './schemas';
import categoriesSelectors from '../categories/selectors';

export const root = R.path([reducer.name]);

export const selectResult = R.pipe(root, R.prop('result'));
export const selectList = R.pipe(root, R.prop('list'));

export const selectHeadlines = createSelector(
  selectResult,
  selectList,
  (result, list) => {
    const headlines = denormalize(
      { headlines: result },
      { headlines: [schemas.headlinesSchema] },
      { headlines: list }
    ).headlines

    if (R.is(Array, headlines)) {
      return R.map(item => ({...item, name: item.title}), headlines);
    }
  }
);

const selectRouteId = (state, props) => R.path(['match', 'params', 'id'], props);

const mapCategories = (categories, categoriesList) => R.reduce((acc, id) => {
  const item = R.prop(id, categoriesList);

  if (R.isNil(item)) {
    return acc;
  }

  return R.append({ value: item.id, label: item.name }, acc);
}, [], categories);

export const selectHeadline = createSelector(
  selectList,
  selectRouteId,
  categoriesSelectors.selectList,
  (list, id, categoriesList) => {
    if (!list || !categoriesList) {
      return;
    }

    const headline = R.prop(id, list);

    if (!headline) {
      return false;
    }

    const categories = R.propOr([], 'category', headline);

    return R.pipe(
      R.dissoc('category'),
      R.assoc('categories', mapCategories(categories, categoriesList)),
      // parseQuestions,
    )(headline);
  },
);

export default {
  selectHeadlines,
  selectHeadline,
};
