import * as R from 'ramda';
import {
  GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS,
  DELETE_CATEGORY_SUCCESS, ADD_CATEGORY_SUCCESS,
  EDIT_CATEGORY_SUCCESS,
} from './constants';

export const initialState = {
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_REQUEST:
      return state;

    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        result: action.result,
        list: action.list,
      };

    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        result: R.filter(R.complement(R.equals)(action.id), state.result),
        list: R.dissoc(action.id, state.list),
      };

    case ADD_CATEGORY_SUCCESS:
      return {
        ...state,
        result: R.append(action.item.id, state.result),
        list: R.assoc(action.item.id, action.item, state.list),
      };
    
    case EDIT_CATEGORY_SUCCESS:
      return {
        ...state,
        list: R.assoc(action.item.id, action.item, state.list),
      };
    
    default:
      return state;
  }
};

const name = 'CATEGORIES';

export default {
  name,
  reducer,
};
