import React from 'react';
import { Modal, ButtonGroup, Button } from 'react-bootstrap';
import { ProcessButton } from './Buttons';

const DeleteModal = ({
  title,
  show,
  onHide,
  onDelete,
  isProcess,
}) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Are you sure you want to delete this item?
      </p>
    </Modal.Body>
    <Modal.Footer>
      <ButtonGroup>
        <ProcessButton isProcess={isProcess} onClick={onDelete} variant='danger'>Delete</ProcessButton>
        <Button onClick={onHide} variant='light'>Сancel</Button>
      </ButtonGroup>
    </Modal.Footer>
  </Modal>
);

DeleteModal.defaultProps = {
  title: 'Delete sequence',
};

export default DeleteModal;
