import React, { useState, useEffect } from 'react';
import { connect } from 'formik';
import * as R from 'ramda';
import { Modal, ButtonGroup, Button } from 'react-bootstrap';
import useModal from '../hooks/useModal';
import { ProcessButton } from '../components/Buttons';

export const useFormikOnChange = (formik) => {
  const [isSame, setIsSame] = useState(true);

  useEffect(() => {
    const newIsSame = R.equals(formik.initialValues, formik.values);

    if (newIsSame !== isSame) {
      setIsSame(newIsSame);
    }
  }, [formik]);

  return [isSame, setIsSame];
};

const ChangeModal = ({
  show,
  onHide,
  submit,
  isProcess,
  hideConfirm,
}) => (
  <Modal backdropClassName='inner-backdrop' show={show} onHide={hideConfirm}>
    <Modal.Header closeButton>
      <Modal.Title>Exit without saving?</Modal.Title>
    </Modal.Header>
    <Modal.Footer>
      <ButtonGroup>
        <ProcessButton onClick={submit} isProcess={isProcess}>Cancel</ProcessButton>
        <Button disabled={isProcess} onClick={onHide} variant='light'>Back to list</Button>
      </ButtonGroup>
    </Modal.Footer>
  </Modal>
);

export const FormikChange = connect(({
  modal = ChangeModal,
  show,
  onHide,
  children,
  onAction,
  formik,
}) => {
  const [isSame] = useFormikOnChange(formik);
  const [confirmModal, openConfirmModal, hideConfirmModal] = useModal();
  const [isProcess, setIsProcess] = useState();

  const onHideHandler = (...args) => {
    if (isSame) {
      return onHide(...args);
    }

    openConfirmModal();
  };

  const confirmAction = () => {
    onHide();
  };

  const submitAction = async () => {
    try {
      // setIsProcess(true);
      hideConfirmModal();
    } catch (error) {
      throw error;
    }
  };

  const childrenRender = children({ show, onHide: onHideHandler });
  const renderModal = modal({
    show: confirmModal,
    onHide: confirmAction,
    submit: submitAction,
    hideConfirm: hideConfirmModal,
    isProcess,
  });

  return (
    <>
      {childrenRender}
      {renderModal}
    </>
  );
});

const withFormikChange = (Component) => {
  const wrapped = (props) => {
    return (
      <FormikChange onHide={props.onHide} onAction={props.onAction}>

        <Component {...props} />  
      </FormikChange>
    );
  };

  return wrapped;
};

export default withFormikChange;
