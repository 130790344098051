export const GET_WEBINARS_REQUEST = 'GET_WEBINARS_REQUEST';
export const GET_WEBINARS_SUCCESS = 'GET_WEBINARS_SUCCESS';

export const DELETE_WEBINAR_REQUEST = 'DELETE_WEBINAR_REQUEST';
export const DELETE_WEBINAR_SUCCESS = 'DELETE_WEBINAR_SUCCESS';

export const EDIT_WEBINAR_REQUEST = 'EDIT_WEBINAR_REQUEST';

export const NEXT_WEBINAR_PAGE = 'NEXT_WEBINAR_PAGE';
export const PREV_WEBINAR_PAGE = 'PREV_WEBINAR_PAGE';
export const SET_WEBINAR_PAGE = 'SET_WEBINAR_PAGE';
