import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import applySelectors from '../utils/applySelectors';
import selectors from '../store/headlines/selectors';
import actions from '../store/headlines/actions';
import categoriesActions from '../store/categories/actions';
import AddHeadLineFormContainer from './AddHeadLineFormContainer';
import Head from '../components/Head/Head';
import { Spinner } from 'react-bootstrap';

const EditCategoryContainer = ({
  getCategories,
  getHeadlines,
  editHeadline,
  history,
  item,
}) => {
  useEffect(() => {
    getCategories();
    getHeadlines();
  }, [getHeadlines, getCategories]);

  useEffect(() => {
    if (item === false) {
      history.push('/headlines');
    }
  }, [item]);

  const editHeadlineHandler = async (data) => {
    try {
      await editHeadline(data);
      history.push('/headlines');
    } catch {}
  };

  return (
    <>
      <Head title='Edit headline' />
      {item ? (
        <AddHeadLineFormContainer
          onSubmit={editHeadlineHandler}
          initialValues={item}
          edit
        />
      ) : (
        <Spinner animation='border' role='status' />
      )}
    </>
  );
};

const mapStateToProps = applySelectors({
  item: selectors.selectHeadline,
});

export default R.pipe(
  connect(
    mapStateToProps,
    {
      ...actions,
      getCategories: categoriesActions.getCategories,
    }
  ),
  withRouter,
)(EditCategoryContainer);
