import * as R from 'ramda';
import api from '../../services/api';
import {
  GET_QUESTIONS_REQUEST, GET_QUESTIONS_SUCCESS,
  ADD_QUESTION_REQUEST, ADD_QUESTION_SUCCESS,
} from './constants';
import toastActions from '../toasts/actions';

const getQuestionsRequest = () => ({
  type: GET_QUESTIONS_REQUEST,
})

const getQuestionsSuccess = (result, list) => ({
  type: GET_QUESTIONS_SUCCESS,
  result,
  list,
})

const addQuestionRequest = () => ({
  type: ADD_QUESTION_REQUEST,
})

const addQuestionSuccess = (item) => ({
  type: ADD_QUESTION_SUCCESS,
  item,
})

const getQuestions = () => async dispatch => {
  try {
    dispatch(getQuestionsRequest());
    const data = await api.getQuestions();
    const result = Object.keys(data);
    const entities = R.mapObjIndexed((el, key) => ({ ...el, id: key, }), data);
    dispatch(getQuestionsSuccess(result, entities))
  } catch {

  }
};

const addQuestion = (data) => async dispatch => {
  try {
    dispatch(addQuestionRequest());
    await api.addQuestion(data);
    dispatch(addQuestionSuccess(data));
    dispatch(toastActions.addToast({ title: 'Question successfully added!' }));
  } catch (error) {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
    throw error;
  }
};

const deleteQuestion = (id) => async dispatch => {
  try {
    await api.deleteQuestion(id);
    dispatch(toastActions.addToast({ title: 'Question successfully deleted!' }));
  } catch (error) {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
    throw error;
  }
};

const editQuestion = (data) => async dispatch => {
  try {
    await api.addQuestion(data);
    dispatch(toastActions.addToast({ title: 'Question successfully saved!' }));
  } catch (error) {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
    throw error;
  }
};

export default {
  getQuestions,
  addQuestion,
  deleteQuestion,
  editQuestion,
};
