import { useEffect } from 'react';
import rtlDetect from 'rtl-detect';

const useRtl = () => {
  useEffect(() => {
    try {
      const language = window.navigator.userLanguage || window.navigator.language;
      const isRtl = rtlDetect.isRtlLang(language);
      if (isRtl) {
        document.querySelector('html').setAttribute('dir', 'rtl');
      }
    } catch {}
  }, []);
}

export default useRtl;
