import * as R from 'ramda';
import api from '../../services/api';
import {
  GET_CATEGORIES_REQUEST, GET_CATEGORIES_SUCCESS,
  DELETE_CATEGORY_REQUEST, DELETE_CATEGORY_SUCCESS,
  ADD_CATEGORY_REQUEST, ADD_CATEGORY_SUCCESS,
  EDIT_CATEGORY_REQUEST, EDIT_CATEGORY_SUCCESS,
} from './constants';
import toastActions from '../toasts/actions';

const getCategoriesRequest = () => ({
  type: GET_CATEGORIES_REQUEST,
})

const getCategoriesSuccess = (result, list) => ({
  type: GET_CATEGORIES_SUCCESS,
  result,
  list,
})

const deleteCategoriesRequest = () => ({
  type: DELETE_CATEGORY_REQUEST,
})

const deleteCategoriesSuccess = (id) => ({
  type: DELETE_CATEGORY_SUCCESS,
  id,
})

const addCategoriesRequest = () => ({
  type: ADD_CATEGORY_REQUEST,
})

const addCategoriesSuccess = (item) => ({
  type: ADD_CATEGORY_SUCCESS,
  item,
})

const editCategoriesRequest = () => ({
  type: EDIT_CATEGORY_REQUEST,
})

const editCategoriesSuccess = (item) => ({
  type: EDIT_CATEGORY_SUCCESS,
  item,
})

const getCategories = () => async dispatch => {
  try {
    dispatch(getCategoriesRequest());
    const data = await api.getCategories();
    const result = Object.keys(data);
    const entities = R.mapObjIndexed((name, key) => ({ id: key, name }), data);
    dispatch(getCategoriesSuccess(result, entities));
  } catch {

  }
};

const deleteCategory = (id) => async dispatch => {
  try {
    dispatch(deleteCategoriesRequest());
    await api.deleteCategory(id);
    dispatch(deleteCategoriesSuccess(id));
    dispatch(toastActions.addToast({ title: 'Category successfully deleted!' }))
  } catch (error) {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }))
    throw error;
  }
};

const addCategory = (data) => async dispatch => {
  try {
    dispatch(addCategoriesRequest());
    await api.addCategory(data);
    dispatch(addCategoriesSuccess(data));
    dispatch(toastActions.addToast({ title: 'Category successfully added!' }));
  } catch (error) {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
    throw error;
  }
};

const editCategory = (data) => async dispatch => {
  try {
    dispatch(editCategoriesRequest());
    await api.addCategory(data);
    dispatch(editCategoriesSuccess(data));
    dispatch(toastActions.addToast({ title: 'Category successfully saved!' }));
  } catch (error) {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
    throw error;
  }
};

export default {
  getCategories,
  deleteCategory,
  addCategory,
  editCategory,
};
