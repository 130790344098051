import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import AddQuestionFormContainer from './AddQuestionFormContainer';
import actions from '../store/categories/actions';

const AddCategoryModalContainer = ({
  show,
  onHide,
}) => {
  return (
    <Modal show={show} onHide={onHide} backdropClassName='question-modal-backdrop'>
      <Modal.Header closeButton>
        <Modal.Title>Add question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddQuestionFormContainer afterSubmit={onHide} />
      </Modal.Body>
    </Modal>
  );
};

export default R.pipe(
  connect(null, actions),
)(AddCategoryModalContainer);
