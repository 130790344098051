import {
  GET_HEADLINES_REQUEST, GET_HEADLINES_SUCCESS,
} from './constants';

export const initialState = {
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HEADLINES_REQUEST:
      return state;

    case GET_HEADLINES_SUCCESS:
      return {
        ...state,
        result: action.result,
        list: action.list,
      };
    
    default:
      return state;
  }
};

const name = 'HEADLINES';

export default {
  name,
  reducer,
};
