import * as R from 'ramda';
import {
  ADD_TOAST, DELETE_TOAST,
} from './constants';

export const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TOAST:
      return R.append({ id: action.id, hideAfter: action.hideAfter, ...action.toast }, state);

    case DELETE_TOAST:
      return R.filter(R.complement(R.propEq('id'))(action.id), state);
    
    default:
      return state;
  }
};

const name = 'TOASTS';

export default {
  name,
  reducer,
};
