import { useState, useRef } from 'react';

const useCaret = () => {
  const [caretPosition, setCaretPosition] = useState();
  const textAreaRef = useRef();
  const handleCaretPosition = () => {
    const startPosition = textAreaRef.current.selectionStart;
    const endPosition = textAreaRef.current.selectionEnd;

    if (startPosition === endPosition) {
      setCaretPosition(startPosition);
    }
  };

  const focusAndSetCuretPosition = (position) => {
    textAreaRef.current.focus();
    setTimeout(() => {
      textAreaRef.current.setSelectionRange(position, position);
      setCaretPosition(position);
    }, 0)
  };

  return {
    textAreaRef,
    caretPosition,
    setCaretPosition,
    handleCaretPosition,
    focusAndSetCuretPosition,
  };
};

export default useCaret;
