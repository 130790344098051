import * as R from 'ramda';
import { normalize } from 'normalizr';
import api from '../../services/api';
import {
  GET_VSLS_REQUEST, GET_VSLS_SUCCESS,
  DELETE_VSL_REQUEST, DELETE_VSL_SUCCESS,
  EDIT_VSL_REQUEST,
  NEXT_VSL_PAGE, PREV_VSL_PAGE, SET_VSL_PAGE,
} from './constants';
import toastActions from '../toasts/actions';
import webinarsActions from '../webinars/actions';
import schemas from './schemas';

const getVSLsRequest = () => ({
  type: GET_VSLS_REQUEST,
});

const getVSLsSuccess = ({ result, entities }, paginationData) => ({
  type: GET_VSLS_SUCCESS,
  result,
  list: entities,
  pagination: paginationData,
});

const deleteVSLRequest = () => ({
  type: DELETE_VSL_REQUEST,
});

const deleteVSLSuccess = (id) => ({
  type: DELETE_VSL_SUCCESS,
  id,
});

const getVSLs = (params) => async dispatch => {
  try {
    dispatch(getVSLsRequest());
    const data = await api.getVSLs(params);
    const paginationData = R.pick(['cursor', 'has_more', 'count'], data);
    const normalizedData = normalize(data.script_templates, [schemas.slidesSchema]);
    dispatch(getVSLsSuccess(normalizedData, paginationData))
  } catch {

  }
};

const deleteVSL = (id) => async dispatch => {
  try {
    dispatch(deleteVSLRequest());
    await api.deleteVSL(id);
    dispatch(deleteVSLSuccess(id))
    dispatch(toastActions.addToast({ title: 'VSL successfully deleted!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const addSlide = (id, email) => async dispatch => {
  try {
    const { id: emailId } = await api.addSlide(id, email);
    dispatch(toastActions.addToast({ title: 'Slide successfully added!' }));
    return emailId;
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const editSlide = (sequenceId, id, email) => async dispatch => {
  try {
    const { id: slideId } = await api.editSlide(sequenceId, id, email);
    dispatch(toastActions.addToast({ title: 'Slide successfully saved!' }));
    return slideId;
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const deleteSlide = (sequenceId, id) => async dispatch => {
  try {
    await api.deleteSlide(sequenceId, id);
    dispatch(toastActions.addToast({ title: 'Slide successfully deleted!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const addVSL = (data) => async dispatch => {
  try {
    const sequence = R.dissoc('script_ids', data);
    const { id } = await api.addVSL(sequence);

    for (let i = 0; i < data.script_ids.length; i++) {
      const slide = R.pipe(
        R.path(['script_ids', i]),
        R.dissoc('id'),
      )(data);
      await api.addSlide(id, slide);
    }
    // dispatch(deleteVSLSuccess(id))
    dispatch(toastActions.addToast({ title: 'VSL successfully added!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const editVSLSuccess = (item) => ({
  type: EDIT_VSL_REQUEST,
  item,
})

const editVSL = data => async dispatch => {
  try {
    const script_ids = R.pipe(
      R.prop('script_ids'),
      R.map(R.prop('id')),
    )(data);

    const sequence = R.pipe(
      R.pick(['id', 'image', 'description', 'name']),
      R.assoc('script_ids', script_ids),
    )(data);

    const { id } = await api.editVSL(sequence);
    dispatch(editVSLSuccess(data))
    dispatch(toastActions.addToast({ title: 'VSL successfully saved!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const getSlide = (sequenceId, id) => async dispatch => {
  try {
    return await api.getSlide(sequenceId, id);
  } catch {
  }
};

const uploadImage = (form) => async dispatch => {
  try {
    const { image_url } = await api.uploadEmailImage(form);
    dispatch(toastActions.addToast({ title: 'Image successfully uploaded!' }));
    return image_url;
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
}

const nextSequencePageAction = () => ({
  type: NEXT_VSL_PAGE,
});
const nextSequencePage = () => dispatch => {
  dispatch(nextSequencePageAction());
};

const prevSequencePageAction = () => ({
  type: PREV_VSL_PAGE,
});
const prevSequencePage = () => dispatch => {
  dispatch(prevSequencePageAction());
};

const setSequencePageAction = (page) => ({
  type: SET_VSL_PAGE,
  page,
});
const setSequencePage = (page) => dispatch => {
  dispatch(setSequencePageAction(page));
};

export default {
  getVSLs,
  deleteVSL,
  addVSL,
  getSlide,
  addSlide,
  editSlide,
  deleteSlide,
  editVSL,
  uploadImage,
  nextSequencePage,
  prevSequencePage,
  setSequencePage,
  getPresentation: webinarsActions.getPresentation,
};
