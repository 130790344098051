import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'formik';
import getAllResults from '../../Headlines/AddHeadlineForm/getAllResults';
import './EmailPreview.scss';

const EmailPreview = ({
  formik,
  questions,
  count,
  show,
  onHide
}) => {
  const preview = useMemo(() => getAllResults(questions, formik.values.body, count), [formik]);

  return (
    <Modal
      className='email-preview-modal'
      backdropClassName='inner-backdrop'
      show={show}
      onHide={onHide}
      enforceFocus={false}
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>Email preview</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='email-preview-modal__preview' dangerouslySetInnerHTML={{ __html: preview }}></div>
      </Modal.Body>
    </Modal>
  );
};

export default connect(EmailPreview);
