import React, { useCallback, useRef, useEffect, useMemo, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { connect } from 'formik';
import getAllResults from '../../Headlines/AddHeadlineForm/getAllResults';
import useLoading from '../../../hooks/withLoading';
import './PresentationPreview.scss';

const PresentationBody = ({
  getPresentation,
  id,
}) => {
  const iframeRef = useRef();
  const [loading, setLoading] = useState(true);
  const count = useMemo(() => ({ num: 3, interval: undefined }), []);
  const fetchPresentation = useCallback(async () => {
    try {
      return await getPresentation(id)
    } catch {

    }
  }, [getPresentation, id]);

  const [, , url] = useLoading(fetchPresentation);

  const iframeSrc = `https://docs.google.com/gview?url=${url}&embedded=true`;

  const iframeTimeoutId = () => {
    if (!url) {
      return;
    }

    count.interval = setInterval(updateIframeSrc, 1000 * count.num);
    count.num += 1;
  };

  const updateIframeSrc = () => {
    iframeRef.current.src = iframeSrc;
    clearInterval(count.interval);
    iframeTimeoutId();
  };

  const iframeLoaded = () => {
    clearInterval(count.interval);
    setLoading(false);
  };

  useEffect(() => {
    iframeTimeoutId();
  }, [url])

  if (!url) {
    return (
      <Spinner animation='border' role='status' />
    );
  }

  return (
    <>
      {loading && <Spinner animation='border' role='status' />}
      <iframe
        title='Presentation preview'
        ref={iframeRef}
        src={iframeSrc}
        width='100%'
        height='300%'
        frameBorder='0'
        onLoad={iframeLoaded}
      />
    </>
  );
};

const PresentationPreview = ({
  show,
  onHide,
  getPresentation,
  id,
}) => (
  <Modal
    className='presentation-preview-modal'
    backdropClassName='inner-backdrop'
    show={show}
    onHide={onHide}
    enforceFocus={false}
    scrollable
  >
    <Modal.Body>
      <PresentationBody
        getPresentation={getPresentation}
        id={id}
      />
    </Modal.Body>
  </Modal>
);

export default connect(PresentationPreview);
