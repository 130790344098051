import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Spinner } from 'react-bootstrap';
import applySelectors from '../../utils/applySelectors';
import questionsSelectors from '../../store/questions/selectors';
import questionsActions from '../../store/questions/actions';
import selectors from '../../store/webinars/selectors';
import actions from '../../store/webinars/actions';
import { AddForm } from '../../components/Webinars';
import AddQuestionModalContainer from '../AddQuestionModalContainer';
import useModal from '../../hooks/useModal';

const EditWebinarContainer = ({
  questionsList,
  questions,
  getQuestions,
  getWebinars,
  editWebinar,
  getSlide,
  addSlide,
  editSlide,
  deleteSlide,
  initialValues,
  uploadImage,
  history,
  pagination,
  setSequencePage,
  getPresentation,
}) => {
  const onBackHandler = () => {
    setSequencePage(0);
    history.push('/webinars');
  };

  useEffect(() => {
    getQuestions();
    getWebinars({ cursor: pagination.currentCursor });
  }, [getQuestions, getWebinars]);

  useEffect(() => {
    if (initialValues === false) {
      onBackHandler();
    }
  }, [initialValues]);

  const [isQuestionModal, openQuestionModal, hideQuestionModal] = useModal();

  if (!questions || !initialValues) {
    return <Spinner />;
  }

  const onSubmitHandler = async (data) => {
    try {
      await editWebinar(data);
    } catch (error) {
    }
  };

  const onDownloadHandler = async () => {
    try {
      const presa = await getPresentation(initialValues.id);
      if (presa) {
        var element = document.createElement('a');
        element.setAttribute('href', presa);
        element.setAttribute('download', '');

        element.style.display = 'none';
        element.click();
      }
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <>
      <AddQuestionModalContainer show={isQuestionModal} onHide={hideQuestionModal} />
      <AddForm
        questionsList={questionsList}
        questions={questions}
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        getEmail={getSlide}
        addEmail={addSlide}
        editEmail={editSlide}
        deleteEmail={deleteSlide}
        openQuestion={openQuestionModal}
        uploadEmailImage={uploadImage}
        onBack={onBackHandler}
        getPresentation={getPresentation}
        onDownload={onDownloadHandler}
        edit
      />
    </>
  );
};

const mapStateToProps = applySelectors({
  questionsList: questionsSelectors.selectQuestions,
  questions: questionsSelectors.selectList,
  initialValues: selectors.selectSequence,
  pagination: selectors.selectPagination,
});

export default R.pipe(
  connect(
    mapStateToProps,
    {
      ...actions,
      getQuestions: questionsActions.getQuestions,
    }
  ),
  withRouter,
)(EditWebinarContainer);
