import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Button } from 'react-bootstrap';
import './Head.scss';

const Head = ({
  title,
  onAdd,
}) => (
  <div className='head'>
    <h1>{title}</h1>
    <ButtonGroup aria-label="First group">
      {onAdd && <Button variant="success" onClick={onAdd}><i className="fas fa-plus-square"></i></Button>}
    </ButtonGroup>
  </div>
);

Head.propTypes = {
  title: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
};

Head.defaultProps = {
  onAdd: undefined,
};

export default Head;
