import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';
import applySelectors from '../../utils/applySelectors';
import useLoading from '../../hooks/withLoading';
import Head from '../../components/Head';
import { List } from '../../components/VSL';
import actions from '../../store/vsl/actions';
import selectors from '../../store/vsl/selectors';
import Pagination from '../../components/Pagination';
import DeleteModal from '../../components/DeleteModal';

const VSLListContainer = ({
  history,
  getVSLs,
  deleteVSL,
  list,
  pagination,
  nextSequencePage,
  prevSequencePage,
}) => {
  const getSequencesPagination = useCallback(
    () => getVSLs({ cursor: pagination.currentCursor }),
    [getVSLs, pagination.currentCursor]
  );
  const [loading] = useLoading(getSequencesPagination);
  const [deleteId, setDeleteId] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const onAddHandler = () => {
    history.push('/vsl/add');
  };

  const onEditHandler = (id) => {
    history.push(`/vsl/edit/${id}`);
  };

  const onDeleteHandler = async () => {
    try {
      setIsDeleting(true);
      await deleteVSL(deleteId);
    } finally {
      setDeleteId(false);
      setIsDeleting(false);
    }
  };

  const openDeleteModal = id => {
    setDeleteId(id);
  };
  const closeDeleteModal = () => {
    if (isDeleting) {
      return;
    }

    setDeleteId(false);
  };

  return (
    <>
      <Head title='VSL' onAdd={onAddHandler} />
      <List
        loading={loading}
        list={list}
        onEdit={onEditHandler}
        onDelete={openDeleteModal}
      />
      <div className='text-center'>
        <Pagination
          page={pagination.current_page}
          total={pagination.total}
          isProcess={loading}
          next={pagination.has_more}
          prev={pagination.current_page}
          onNext={nextSequencePage}
          onPrev={prevSequencePage}
        />
      </div>
      <DeleteModal
        title='Delete VSL'
        show={deleteId}
        onHide={closeDeleteModal}
        onDelete={onDeleteHandler}
        isProcess={isDeleting}
      />
    </>
  );
};

const mapStateToProps = applySelectors({
  list: selectors.selectSequences,
  pagination: selectors.selectPagination,
});

export default R.pipe(
  connect(mapStateToProps, actions),
  withRouter,
)(VSLListContainer);;
