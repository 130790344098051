import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Modal, Button, ButtonGroup, Form, Spinner } from 'react-bootstrap';
import * as R from 'ramda';
import { connect, Formik } from 'formik'
import * as yup from 'yup';
import testResult, { errorMessage } from '../../Headlines/AddHeadlineForm/testResult';
import EmailInput from './EmailInput';
import useLoading from '../../../hooks/withLoading';
import { ProcessButton } from '../../Buttons';
import './Modal.scss';
import { FormikChange } from '../../../hocs/withModalOnChange';

const Footer = ({
  formik,
  isProcess,
  submitText,
  onDeleteHandler,
  openPreviewModal,
}) => (
  <Modal.Footer>
    <ButtonGroup>
      <ProcessButton onClick={formik.handleSubmit} isProcess={isProcess}>{submitText}</ProcessButton>
      <Button disabled={isProcess} onClick={onDeleteHandler} variant='light'>Back to list</Button>
    </ButtonGroup>
  </Modal.Footer>
);

const EmailModal = connect(({
  onHide,
  formik,
  onDelete,
  isProcess,
  edit,
  openPreviewModal,
  ...props
}) => {
  const [showErrors, setIsShowErrors] = useState(true);
  const onDeleteHandler = () => {
    setIsShowErrors(false);
    onHide();
  };

  const handleSubmitAndHide = async () => {
    formik.handleSubmit();
  };

  const submitText = edit ? 'Save' : 'Add';

  return (
    <>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <EmailInput {...props} showErrors={showErrors} />
        </Form>
      </Modal.Body>
      <Footer
        formik={formik}
        isProcess={isProcess}
        submitText={submitText}
        onDeleteHandler={onDeleteHandler}
        openPreviewModal={openPreviewModal}
      />
    </>
  );
});

const ModalWithFormik = ({
  questions,
  initialValues,
  onSubmit,
  show,
  id,
  newEmail,
  formik,
  onLoadEmail,
  ...props
}) => {
  const [isProcess, setIsProcess] = useState();

  const fetchEmail = useCallback(async () => {
    if (R.isNil(id)) {
      return;
    }

    return await onLoadEmail(id, formik);
  }, [id])

  const [loading,, data, setData] = useLoading(fetchEmail, fetchEmail);

  const schema = useMemo(() => yup.object({
    subject: yup.string().test({
      message: errorMessage(questions),
      test: value => testResult(questions, value)[0],
    }),
    body: yup.string().required().test({
      message: errorMessage(questions),
      test: value => testResult(questions, value)[0],
    }),
  }), [questions]);

  const [emails, setEmails] = useState([]);

  const onNewEmail = (data) => {
    setEmails(R.append(data));
  };

  const onSubmitHandler = useCallback(async (data) => {
    try {
      setIsProcess(true);
      await onSubmit(data, formik);
      setData(data);
    } finally {
      setIsProcess(false);
    }
  }, [id])

  if (R.isNil(id)) {
    return null;
  }

  if (loading) {
    return (
      <Modal className='presentation-modal' show={show} onHide={props.onHide} size='xl' scrollable>
        <Modal.Header closeButton>
          <Modal.Title>Add slide</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-center'>
          <Spinner animation='border' role='status' />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Formik
      schema={schema}
      validationSchema={schema}
      onSubmit={onSubmitHandler}
      initialValues={data}
      enableReinitialize
    >
      <>
        <FormikChange onHide={props.onHide} onAction={R.identity}>
          {({ onHide }) => (
            <Modal className='presentation-modal' show={show} onHide={onHide} size='xl' enforceFocus={false}>
              <Modal.Header closeButton>
                <Modal.Title>Add slide</Modal.Title>
              </Modal.Header>
              <EmailModal
                {...props}
                onHide={onHide}
                edit={newEmail}
                isProcess={isProcess}
              />
            </Modal>
          )}
        </FormikChange>
      </>
    </Formik>
  )
}

export default connect(ModalWithFormik);
