import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import List from '../components/List';
import applySelectors from '../utils/applySelectors';
import actions from '../store/questions/actions';
import selectors from '../store/questions/selectors';
import Head from '../components/Head';
import useLoading from '../hooks/withLoading';
import useErrors from '../hooks/useErrors';
import ErrorsModal from '../components/ErrorsModal';

const QuestionsContainer = ({
  getQuestions,
  deleteQuestion,
  list,
  history,
}) => {
  const [loading, loadQuestions] = useLoading(getQuestions);
  const onDeleteQuestion = useErrors(deleteQuestion);

  const onAddHandler = () => {
    history.push('/questions/add');
  };

  const editQuestion = (id) => {
    history.push(`/questions/edit/${id}`);
  };

  const deleteHandler = async (id) => {
    try {
      await onDeleteQuestion.handler(id);
    } finally {
      await loadQuestions();
    }
  };

  return (
    <>
      <Head title='Questions' onAdd={onAddHandler} />
      <List
        list={list}
        onEdit={editQuestion}
        onDelete={deleteHandler}
        loading={loading}
      />
      <ErrorsModal errorsHook={onDeleteQuestion} />
    </>
  );
};

const mapStateToProps = applySelectors({
  list: selectors.selectQuestions
});

export default R.pipe(
  connect(mapStateToProps, actions),
  withRouter,
)(QuestionsContainer);
