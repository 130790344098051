import React from 'react';
import { Button } from 'react-bootstrap';

const EditButton = ({
  id,
  onClick,
}) => {
  const onEditHandler = async () => {
    try {
      await onClick(id)
    } catch {}
  };

  return (
    <Button onClick={onEditHandler}>
      <i className='fas fa-edit' />
    </Button>
  );
};

export default EditButton;
