import * as R from 'ramda';

export const createPlaceholder = value => `[[[${value}]]]`

const putTextToPosition = (caretPosition, originalString) => (value) => {
  let finalString, newCaretPosition;

  if (R.isNil(caretPosition)) {
    finalString = `${originalString} ${createPlaceholder(value)}`;
    newCaretPosition = finalString.length;
  } else {
    const leftPart = originalString.slice(0, caretPosition);
    const rightPart = originalString.slice(caretPosition);
    const finalLeftPart = `${R.trim(leftPart)}${createPlaceholder(value)}`;
    finalString = `${finalLeftPart}${rightPart}`;
    newCaretPosition = finalLeftPart.length;
  }

  return {
    finalString,
    newCaretPosition,
  };
}

export default putTextToPosition;
