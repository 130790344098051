import * as R from 'ramda';
import reducer from './reducer';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import schemas from './schemas';
import categoriesSelectors from '../categories/selectors';

export const root = R.path([reducer.name]);

export const selectResult = R.pipe(root, R.prop('result'));
export const selectList = R.pipe(root, R.prop('list'));

export const selectSequences = createSelector(
  selectResult,
  selectList,
  (result, list) => denormalize(result, [schemas.slidesSchema], list),
);

const selectRouteId = (state, props) => R.path(['match', 'params', 'id'], props);

export const selectSequence = createSelector(
  selectList,
  selectRouteId,
  (list, id) => {
    if (!list) {
      return;
    }

    const sequence = R.path(['script_templates', id], list);

    if (!sequence) {
      return false;
    }

    return sequence;
  },
);

const selectPaginationData = R.pipe(root, R.prop('pagination'));
export const selectPagination = createSelector(
  selectPaginationData,
  (pagination) => {
    const currentCursor = R.prop(pagination.current_page, pagination.cursors);
    const total = Math.ceil(pagination.count / pagination.per_page);

    return {
      ...R.pick(['cursor', 'has_more', 'current_page'], pagination),
      currentCursor,
      total,
    };
  },
);

export default {
  selectSequences,
  selectSequence,
  selectPagination,
};
