import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import AddQuestionForm from '../components/Questions/AddQuestionForm';
import actions from '../store/questions/actions';

const AddQuestionFormContainer = ({
  addQuestion,
  afterSubmit,
}) => {
  const addQuestionHandler = async (data, formik) => {
    try {
      await addQuestion(data);

      if (R.is(Function, afterSubmit)) {
        afterSubmit(data, formik);
      }
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <AddQuestionForm
      onSubmit={addQuestionHandler}
    />
  );
};

export default R.pipe(
  connect(null, actions),
  withRouter,
)(AddQuestionFormContainer);
