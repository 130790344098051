import React from 'react';
import { Modal, ListGroup } from 'react-bootstrap';
import * as R from 'ramda';

const ErrorsModal = ({ errorsHook }) => (
  <Modal show={errorsHook.errors.length} onHide={errorsHook.clearErrors}>
    <Modal.Header closeButton>
      <Modal.Title>Error</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ListGroup>
        {R.map(item => (
          <ListGroup.Item key={item.message} variant='danger'>{item.message}</ListGroup.Item>
        ), errorsHook.errors)}
      </ListGroup>
    </Modal.Body>
  </Modal>
);

export default ErrorsModal;
