import QuestionsContainer from './containers/QuestionsContainer';
import CategoriesContainer from './containers/CategoriesContainer';
import HeadlinesContainer from './containers/HeadlinesContainer';
import AddCategoryContainer from './containers/AddCategoryContainer';
import EditCategoryContainer from './containers/EditCategoryContainer';
import AddQuestionContainer from './containers/AddQuestionContainer';
import AddHeadlineContainer from './containers/AddHeadlineContainer';
import EditHeadLineContainer from './containers/EditHeadlineContainer';
import EditQuestionsContainer from './containers/EditQuestionsContainer';
import EmailsListContainer from './containers/EmailsListContainer';
import AddEmailContainer from './containers/AddEmailContainer';
import EditEmailContainer from './containers/EditEmailContainer';
import WebinarsListContainer from './containers/WebinarsContainers/WebinarsListContainer';
import AddWebinarContainer from './containers/WebinarsContainers/AddWebinarContainer';
import VSLListContainer from './containers/VSLContainers/VSLListContainer';
import AddVSLContainer from './containers/VSLContainers/AddVSLContainer';
import EditWebinarContainer from './containers/WebinarsContainers/EditWebinarContainer';
import EditVSLContainer from './containers/VSLContainers/EditVSLContainer';

export default [
  {
    path: '/',
    component: CategoriesContainer,
    exact: true,
  },
  {
    path: '/categories',
    component: CategoriesContainer,
    exact: true,
  },
  {
    path: '/categories/add',
    component: AddCategoryContainer,
  },
  {
    path: '/categories/edit/:id',
    component: EditCategoryContainer,
  },
  {
    path: '/questions',
    component: QuestionsContainer,
    exact: true,
  },
  {
    path: '/questions/add',
    component: AddQuestionContainer,
  },
  {
    path: '/questions/edit/:id',
    component: EditQuestionsContainer,
  },
  {
    path: '/headlines',
    component: HeadlinesContainer,
    exact: true,
  },
  {
    path: '/headlines/add',
    component: AddHeadlineContainer,
  },
  {
    path: '/headlines/edit/:id',
    component: EditHeadLineContainer,
  },
  {
    path: '/emails',
    component: EmailsListContainer,
    exact: true,
  },
  {
    path: '/emails/add',
    component: AddEmailContainer,
  },
  {
    path: '/emails/edit/:id',
    component: EditEmailContainer,
  },
  {
    path: '/webinars',
    component: WebinarsListContainer,
    exact: true,
  },
  {
    path: '/webinars/add',
    component: AddWebinarContainer,
  },
  {
    path: '/webinars/edit/:id',
    component: EditWebinarContainer,
  },
  {
    path: '/vsl',
    component: VSLListContainer,
    exact: true,
  },
  {
    path: '/vsl/add',
    component: AddVSLContainer,
  },
  {
    path: '/vsl/edit/:id',
    component: EditVSLContainer,
  },
];
