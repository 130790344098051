import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import AddCategoryForm from '../components/Category/AddCategoryForm';
import actions from '../store/categories/actions';
import applySelectors from '../utils/applySelectors';
import selectors from '../store/categories/selectors';
import Head from '../components/Head/Head';
import { Spinner } from 'react-bootstrap';

const EditCategoryContainer = ({
  getCategories,
  editCategory,
  history,
  item,
}) => {
  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    if (item === false) {
      history.push('/categories');
    }
  }, [item]);

  const editCategoryHandler = async (data, formik) => {
    try {
      await editCategory(data);
      history.push('/categories');
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <>
      <Head title='Edit category' />
      {item ? (
        <AddCategoryForm
          onSubmit={editCategoryHandler}
          initialValues={item}
          edit
        />
      ) : (
        <Spinner animation='border' role='status' />
      )}
    </>
  );
};

const mapStateToProps = applySelectors({
  item: selectors.selectCategory
});

export default R.pipe(
  connect(mapStateToProps, actions),
  withRouter,
)(EditCategoryContainer);
