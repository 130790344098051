export const GET_SEQUENCES_REQUEST = 'GET_SEQUENCES_REQUEST';
export const GET_SEQUENCES_SUCCESS = 'GET_SEQUENCES_SUCCESS';

export const DELETE_SEQUENCE_REQUEST = 'DELETE_SEQUENCE_REQUEST';
export const DELETE_SEQUENCE_SUCCESS = 'DELETE_SEQUENCE_SUCCESS';

export const EDIT_SEQUENCE_REQUEST = 'EDIT_SEQUENCE_REQUEST';

export const NEXT_SEQUENCE_PAGE = 'NEXT_SEQUENCE_PAGE';
export const PREV_SEQUENCE_PAGE = 'PREV_SEQUENCE_PAGE';
export const SET_SEQUENCE_PAGE = 'SET_SEQUENCE_PAGE';
