import * as R from 'ramda';
import { normalize } from 'normalizr';
import api from '../../services/api';
import {
  GET_SEQUENCES_REQUEST, GET_SEQUENCES_SUCCESS,
  DELETE_SEQUENCE_REQUEST, DELETE_SEQUENCE_SUCCESS,
  EDIT_SEQUENCE_REQUEST,
  NEXT_SEQUENCE_PAGE, PREV_SEQUENCE_PAGE, SET_SEQUENCE_PAGE,
} from './constants';
import toastActions from '../toasts/actions';
import schemas from './schemas';

const getSequencesRequest = () => ({
  type: GET_SEQUENCES_REQUEST,
});

const getSequencesSuccess = ({ result, entities }, paginationData) => ({
  type: GET_SEQUENCES_SUCCESS,
  result,
  list: entities,
  pagination: paginationData,
});

const deleteSequenceRequest = () => ({
  type: DELETE_SEQUENCE_REQUEST,
});

const deleteSequenceSuccess = (id) => ({
  type: DELETE_SEQUENCE_SUCCESS,
  id,
});

const getSequences = (params) => async dispatch => {
  try {
    dispatch(getSequencesRequest());
    const data = await api.getSequences(params);
    const paginationData = R.pick(['cursor', 'has_more', 'count'], data);
    const normalizedData = normalize(data.script_templates, [schemas.emailsSchema]);
    dispatch(getSequencesSuccess(normalizedData, paginationData))
  } catch {

  }
};

const deleteSequence = (id) => async dispatch => {
  try {
    dispatch(deleteSequenceRequest());
    await api.deleteSequence(id);
    dispatch(deleteSequenceSuccess(id))
    dispatch(toastActions.addToast({ title: 'Sequence successfully deleted!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const addEmail = (id, email) => async dispatch => {
  try {
    const { id: emailId } = await api.addEmail(id, email);
    dispatch(toastActions.addToast({ title: 'Email successfully added!' }));
    return emailId;
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const editEmail = (sequenceId, id, email) => async dispatch => {
  try {
    const { id: emailId } = await api.editEmail(sequenceId, id, email);
    dispatch(toastActions.addToast({ title: 'Email successfully saved!' }));
    return emailId;
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const deleteEmail = (sequenceId, id) => async dispatch => {
  try {
    await api.deleteEmail(sequenceId, id);
    dispatch(toastActions.addToast({ title: 'Email successfully deleted!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const addSequence = (data) => async dispatch => {
  try {
    const sequence = R.dissoc('script_ids', data);
    const { id } = await api.addSequence(sequence);

    for (let i = 0; i < data.script_ids.length; i++) {
      const email = R.pipe(
        R.path(['script_ids', i]),
        R.dissoc('id'),
      )(data);
      await api.addEmail(id, email);
    }
    // dispatch(deleteSequenceSuccess(id))
    dispatch(toastActions.addToast({ title: 'Sequence successfully added!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const editSequenceSuccess = (item) => ({
  type: EDIT_SEQUENCE_REQUEST,
  item,
})

const editSequence = data => async dispatch => {
  try {
    const script_ids = R.pipe(
      R.prop('script_ids'),
      R.map(R.prop('id')),
    )(data);

    const sequence = R.pipe(
      R.pick(['id', 'image', 'description', 'name']),
      R.assoc('script_ids', script_ids),
    )(data);

    const { id } = await api.editSequence(sequence);
    dispatch(editSequenceSuccess(data))
    dispatch(toastActions.addToast({ title: 'Sequence successfully saved!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const getEmail = (sequenceId, id) => async dispatch => {
  try {
    return await api.getEmail(sequenceId, id);
  } catch {
  }
};

const uploadEmailImage = (form) => async dispatch => {
  try {
    const { image_url } = await api.uploadEmailImage(form);
    dispatch(toastActions.addToast({ title: 'Image successfully uploaded!' }));
    return image_url;
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
}

const nextSequencePageAction = () => ({
  type: NEXT_SEQUENCE_PAGE,
});
const nextSequencePage = () => dispatch => {
  dispatch(nextSequencePageAction());
};

const prevSequencePageAction = () => ({
  type: PREV_SEQUENCE_PAGE,
});
const prevSequencePage = () => dispatch => {
  dispatch(prevSequencePageAction());
};

const setSequencePageAction = (page) => ({
  type: SET_SEQUENCE_PAGE,
  page,
});
const setSequencePage = (page) => dispatch => {
  dispatch(setSequencePageAction(page));
};

export default {
  getSequences,
  deleteSequence,
  addSequence,
  getEmail,
  addEmail,
  editEmail,
  deleteEmail,
  editSequence,
  uploadEmailImage,
  nextSequencePage,
  prevSequencePage,
  setSequencePage,
};
