import React from 'react'
import {
  BrowserRouter as Router,
  Switch, Route
} from 'react-router-dom';
import { Provider } from 'react-redux'
import * as R from 'ramda';
import configureStore from './configureStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import MainLayout from './layouts/MainLayout';
import ToastsContainer from './containers/ToastsContainer';
import routes from './routes';
import useRtl from './hooks/useRtl';

const store = configureStore();

const App = () => {
  useRtl();

  return (
    <Provider store={store}>
      <Router>
        <MainLayout>
          <Switch>
            {R.map(({ component: Component, ...props }) => (
              <Route key={props.path} {...props}>
                <Component />
              </Route>
            ), routes)}
          </Switch>
        </MainLayout>
      </Router>
      <ToastsContainer />
    </Provider>
  );
};

export default App;
