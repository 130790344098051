import * as R from 'ramda';
import api from '../../services/api';
import {
  GET_HEADLINES_REQUEST, GET_HEADLINES_SUCCESS,
  DELETE_HEADLINE_REQUEST, DELETE_HEADLINE_SUCCESS,
} from './constants';
import toastActions from '../toasts/actions';

const getHeadlinesRequest = () => ({
  type: GET_HEADLINES_REQUEST,
})

const getHeadlinesSuccess = (result, list) => ({
  type: GET_HEADLINES_SUCCESS,
  result,
  list,
})

const deleteHeadlineRequest = () => ({
  type: DELETE_HEADLINE_REQUEST,
})

const deleteHeadlineSuccess = (id) => ({
  type: DELETE_HEADLINE_SUCCESS,
  id,
})

const getHeadlines = () => async dispatch => {
  try {
    dispatch(getHeadlinesRequest());
    const data = await api.getHeadlines();
    const result = Object.keys(data);
    const entities = R.mapObjIndexed((item, key) => ({ id: key, ...item }), data);
    dispatch(getHeadlinesSuccess(result, entities))
  } catch {

  }
};

const prepareHeadlineBody = data => R.pipe(
  R.assoc('categories', R.map(item => item.value, data.categories)),
  R.dissoc('questions'),
)(data);

const addHeadline = (data) => async dispatch => {
  try {
    dispatch(getHeadlinesRequest());
    const bodyRequest = prepareHeadlineBody(data);
    await api.addHeadline(bodyRequest);
    dispatch(getHeadlinesSuccess())
    dispatch(toastActions.addToast({ title: 'HeadLine successfully added!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const editHeadline = (data) => async dispatch => {
  try {
    dispatch(getHeadlinesRequest());
    const bodyRequest = prepareHeadlineBody(data);
    await api.addHeadline(bodyRequest);
    dispatch(getHeadlinesSuccess())
    dispatch(toastActions.addToast({ title: 'HeadLine successfully saved!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const deleteHeadline = (id) => async dispatch => {
  try {
    dispatch(deleteHeadlineRequest());
    await api.deleteHeadline(id);
    await getHeadlines()(dispatch);
    dispatch(deleteHeadlineSuccess(id));
    dispatch(toastActions.addToast({ title: 'Headline successfully deleted!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

export default {
  getHeadlines,
  addHeadline,
  deleteHeadline,
  editHeadline,
};
