import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const ProcessButton = ({
  isProcess,
  children,
  ...props
}) => (
  <Button disabled={isProcess} {...props}>
    {isProcess ? (
      <Spinner
        as='span'
        animation='border'
        size='sm'
        role='status'
        aria-hidden='true'
      />
    ) : children}
  </Button>
)

export default ProcessButton;
