import React from 'react';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import * as R from 'ramda';
import * as yup from 'yup';
import { Form, ButtonGroup, Button } from 'react-bootstrap';

const schema = yup.object({
  id: yup.string().required(),
  name: yup.string().required(),
  examples: yup.array().of(yup.string().required()).min(3).required(),
});

const AddQuestionForm = ({
  edit,
  initialValues,
  onSubmit,
}) => (
  <Formik
    schema={schema}
    validationSchema={schema}
    onSubmit={onSubmit}
    initialValues={initialValues}
  >
    {
      ({
        isSubmitting,
        values,
        handleChange,
        handleSubmit,
        touched,
        errors,
        isValid,
        handleBlur,
        submitCount,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId='id'>
            <Form.Label>Id</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter id'
              name='id'
              value={values.id}
              onChange={handleChange}
              isValid={touched.id && !errors.id}
              isInvalid={submitCount && !!errors.id}
              readOnly={edit}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.id}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter name'
              name='name'
              value={values.name}
              onChange={handleChange}
              isValid={touched.name && !errors.name}
              isInvalid={submitCount && !!errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <FieldArray
            name='examples'
            render={arrayHelpers => (
              <>
                {values.examples.map((item, idx) => (
                  <Form.Group key={idx} controlId={`examples.${idx}`}>
                    {idx === 0 && (
                      <Form.Label>Examples</Form.Label>
                    )}
                    <div className='form__delete'>
                      <Form.Control
                        type='text'
                        placeholder='Enter example'
                        name={`examples.${idx}`}
                        value={item}
                        onChange={handleChange}
                        isValid={touched.examples && !errors.examples}
                        isInvalid={submitCount && !!errors.examples}
                      />
                      <ButtonGroup aria-label='Examples group'>
                        <Button variant='danger' onClick={() => arrayHelpers.remove(idx)}>
                          <i className='fas fa-trash-alt'></i>
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Form.Group>
                ))}

                <Form.Group>
                  <Form.Control
                    type='hidden'
                    isInvalid={submitCount && !!errors.examples}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {R.is(Array, errors.examples) ? errors.examples.filter(R.complement(R.isNil)).join(', ') : errors.examples}
                  </Form.Control.Feedback>
                  <div className='text-right'>
                    <ButtonGroup aria-label='Add example'>
                      <Button variant='success' onClick={() => arrayHelpers.push('')}>
                        <i className='fas fa-plus-square'></i>
                      </Button>
                    </ButtonGroup>
                  </div>
                </Form.Group>
              </>
            )}
          />

          {!edit && (
            <Button disabled={isSubmitting} variant='primary' type='submit'>
              Add
            </Button>
          )}
          {edit && (
            <Button disabled={isSubmitting} variant='primary' type='submit'>
              Save
            </Button>
          )}
        </Form>
      )
    }
  </Formik>
);

AddQuestionForm.propTypes = {
  initialValues: PropTypes.shape({}),
  edit: PropTypes.bool,
};

AddQuestionForm.defaultProps = {
  initialValues: {
    id: '',
    name: '',
    examples: ['', '', ''],
  },
  edit: false,
};

export default AddQuestionForm;
