import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Modal } from 'react-bootstrap';
import AddCategoryFormContainer from './AddCategoryFormContainer';
import { connect as connectFormik } from 'formik';
import actions from '../store/categories/actions';

const AddCategoryModalContainer = ({
  show,
  onHide,
  ...props
}) => {
  const onHideAfterSubmit = (data, formik) => {
    const categories = R.path(['formik', 'values', 'categories'], props);
    const value = { label: data.name, value: data.id };
    props.formik.setFieldValue(
      'categories',
      R.is(Array, categories) ? R.append(value, categories) : [value],
    );
    onHide(data, formik);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddCategoryFormContainer afterSubmit={onHideAfterSubmit} />
      </Modal.Body>
    </Modal>
  );
};

export default R.pipe(
  connect(null, actions),
  connectFormik,
)(AddCategoryModalContainer);
