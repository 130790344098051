import uuid from 'uuid/v4';
import {
  ADD_TOAST, DELETE_TOAST
} from './constants';

const addToast = (toast, hideAfter = 2000) => ({
  type: ADD_TOAST,
  id: uuid(),
  hideAfter,
  toast,
});

const deleteToastAction = id => ({
  type: DELETE_TOAST,
  id,
});

const deleteToast = (id) => dispatch => {
  dispatch(deleteToastAction(id));
};

export default {
  addToast,
  deleteToast,
};
