import { useState, useCallback } from 'react';
import * as R from 'ramda';

const useErrors = (fn) => {
  const [errors, setErrors] = useState([]);
  const clearErrors = useCallback(() => setErrors([]), [setErrors]);

  const handler = useCallback(async (...args) => {
    try {
      await fn(...args);
    } catch (error) {
      const errors = R.path(['response', 'data', 'errors'], error);
      if (R.is(Array, errors)) {
        setErrors(errors);
        return
      }

      if (R.is(String, errors)) {
        const errorList = [{ message: errors }]
        setErrors(errorList);
        return
      }
    }
  }, [fn, setErrors]);

  return {
    errors,
    clearErrors,
    handler,
  };
};

export default useErrors;
