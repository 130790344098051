export const GET_VSLS_REQUEST = 'GET_VSLS_REQUEST';
export const GET_VSLS_SUCCESS = 'GET_VSLS_SUCCESS';

export const DELETE_VSL_REQUEST = 'DELETE_VSL_REQUEST';
export const DELETE_VSL_SUCCESS = 'DELETE_VSL_SUCCESS';

export const EDIT_VSL_REQUEST = 'EDIT_VSL_REQUEST';

export const NEXT_VSL_PAGE = 'NEXT_VSL_PAGE';
export const PREV_VSL_PAGE = 'PREV_VSL_PAGE';
export const SET_VSL_PAGE = 'SET_VSL_PAGE';
