import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import { ProcessButton } from '../Buttons';

const Show = ({
  children,
  show,
}) => {
  if (!show) {
    return null;
  }

  return children;
};

const Pagination = ({
  isProcess,
  onPrev,
  onNext,
  prev,
  next,
  page,
  total,
}) => (
  <ButtonGroup>
    <Show show={total}>
      <Button disabled variant='light'>{page + 1}/{total}</Button>
    </Show>
    <ProcessButton isProcess={isProcess} disabled={!prev || isProcess} variant='light' onClick={onPrev}>Previous page</ProcessButton>
    <ProcessButton isProcess={isProcess} disabled={!next || isProcess} variant='light' onClick={onNext}>Next page</ProcessButton>
  </ButtonGroup>
);

export default Pagination;
