import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MainLayout = ({ children }) => (
  <div>
    <Navbar bg='light' expand='lg'>
      <Navbar.Brand as={Link} to='/'>Admin</Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='mr-auto'>
          <Nav.Link as={Link} to='/categories'>Categories</Nav.Link>
          <Nav.Link as={Link} to='/questions'>Questions</Nav.Link>
          <Nav.Link as={Link} to='/headlines'>Headlines</Nav.Link>
          <Nav.Link as={Link} to='/emails'>Emails</Nav.Link>
          <Nav.Link as={Link} to='/webinars'>Webinars</Nav.Link>
          <Nav.Link as={Link} to='/vsl'>VSL</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    <Container className='app-container'>
      {children}
    </Container>
  </div>
);

export default MainLayout;
