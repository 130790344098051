import React from 'react';
import * as R from 'ramda';
import { connect } from 'formik';
import { Form, ButtonGroup, Button } from 'react-bootstrap';
import putTextToPosition from './putTextToPosition';

const AddQuestions = ({
  questionsList,
  openQuestion,
  formik,
  onAddClick,
  focusAndSetCuretPosition,
  caretPosition,
  updateCombinations,
}) => {
  const handleAddQuestion = (value) => {
    if (R.isNil(value)) {
      return;
    }

    if (R.is(Function, onAddClick)) {
      onAddClick(value);
      return;
    }

    let originalString = R.propOr('', 'result', formik.values);
    const { finalString, newCaretPosition } = putTextToPosition(caretPosition, originalString)(value);

    formik.setFieldValue('result', R.trim(finalString));
    updateCombinations(R.trim(finalString))
    focusAndSetCuretPosition(newCaretPosition);
  };

  return (
    <Form.Group controlId='questions'>
      <Form.Label>Click on + to add question placeholder</Form.Label>
      <div className='headline-form__result-items'>
        {R.is(Array, questionsList) && R.map(item => (
          <div className='headline-form__result-item'>
            <Form.Control as='div'>{item.name}</Form.Control>
            <ButtonGroup aria-label='Add example'>
              <Button variant='success' onClick={() => handleAddQuestion(item.id)}>
                <i className='fas fa-plus-square'></i>
              </Button>
            </ButtonGroup>
          </div>
        ), questionsList)}
        <Button onClick={openQuestion} type='button' variant='link'>Add New Question</Button>
      </div>
    </Form.Group>
  );
};

export default connect(AddQuestions);
