import * as R from 'ramda';
import { getPlaceholders } from './getAllResults';

const testResult = (questions, value) => {
  if (!R.is(String, value)) {
    return [true, ''];
  }

  const questionsMatches = getPlaceholders(value);

  for (let i = 0; i < questionsMatches.length; i++) {
    const question = questionsMatches[i]
    const hasQuestion = R.prop(question, questions);

    if (R.isNil(hasQuestion)) {
      return [false, question];
    }
  }

  return [true, ''];
};

export const errorMessage = questions => (data) => {
  const [, question] = testResult(questions, data.value);
  return `Question [${question}] does not exist`;
}

export default testResult;
