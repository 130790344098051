import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';
import applySelectors from '../utils/applySelectors';
import useLoading from '../hooks/withLoading';
import Head from '../components/Head';
import { List } from '../components/Emails';
import actions from '../store/emails/actions';
import selectors from '../store/emails/selectors';
import { Modal, ButtonGroup, Button } from 'react-bootstrap';
import { ProcessButton } from '../components/Buttons';
import Pagination from '../components/Pagination';
import DeleteModal from '../components/DeleteModal';

const EmailsListContainer = ({
  history,
  getSequences,
  deleteSequence,
  list,
  pagination,
  nextSequencePage,
  prevSequencePage,
}) => {
  const getSequencesPagination = useCallback(
    () => getSequences({ cursor: pagination.currentCursor }),
    [getSequences, pagination.currentCursor]
  );
  const [loading] = useLoading(getSequencesPagination);
  const [deleteId, setDeleteId] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const onAddHandler = () => {
    history.push('/emails/add');
  };

  const onEditHandler = (id) => {
    history.push(`/emails/edit/${id}`);
  };

  const onDeleteHandler = async () => {
    try {
      setIsDeleting(true);
      await deleteSequence(deleteId);
    } finally {
      setDeleteId(false);
      setIsDeleting(false);
    }
  };

  const openDeleteModal = id => {
    setDeleteId(id);
  };
  const closeDeleteModal = () => {
    if (isDeleting) {
      return;
    }

    setDeleteId(false);
  };

  return (
    <>
      <Head title='Email sequences' onAdd={onAddHandler} />
      <List
        loading={loading}
        list={list}
        onEdit={onEditHandler}
        onDelete={openDeleteModal}
      />
      <div className='text-center'>
        <Pagination
          page={pagination.current_page}
          total={pagination.total}
          isProcess={loading}
          next={pagination.has_more}
          prev={pagination.current_page}
          onNext={nextSequencePage}
          onPrev={prevSequencePage}
        />
      </div>
      <DeleteModal
        show={deleteId}
        onHide={closeDeleteModal}
        onDelete={onDeleteHandler}
        isProcess={isDeleting}
      />
    </>
  );
};

const mapStateToProps = applySelectors({
  list: selectors.selectSequences,
  pagination: selectors.selectPagination,
});

export default R.pipe(
  connect(mapStateToProps, actions),
  withRouter,
)(EmailsListContainer);;
