import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import AddHeadlineForm from '../components/Headlines/AddHeadlineForm';
import actions from '../store/headlines/actions';
import useModal from '../hooks/useModal';
import applySelectors from '../utils/applySelectors';
import questionsActions from '../store/questions/actions';
import categoriesSelectors from '../store/categories/selectors';
import questionsSelectors from '../store/questions/selectors';
import { Spinner } from 'react-bootstrap';
import AddCategoryModalContainer from './AddCategoryModalContainer';
import AddQuestionModalContainer from './AddQuestionModalContainer';

const AddHeadLineFormContainer = ({
  getCategories,
  getQuestions,
  categoriesList,
  questionsList,
  questions,
  onSubmit,
  ...rest
}) => {
  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const [isCategoryModal, openCategoryModal, hideCategoryModal] = useModal();
  const [isQuestionModal, openQuestionModal, hideQuestionModal] = useModal();

  return (
    <>
      <AddQuestionModalContainer show={isQuestionModal} onHide={hideQuestionModal} />
      {(categoriesList && questionsList) ? (
        <AddHeadlineForm
          categoriesList={categoriesList}
          questionsList={questionsList}
          questions={questions}
          onSubmit={onSubmit}
          openCategory={openCategoryModal}
          openQuestion={openQuestionModal}
          {...rest}
        >
          <AddCategoryModalContainer show={isCategoryModal} onHide={hideCategoryModal} />
        </AddHeadlineForm>
      ) : (
        <Spinner animation='border' role='status' />
      )}
    </>
  );
};

const mapStateToProps = applySelectors({
  categoriesList: categoriesSelectors.selectCategories,
  questionsList: questionsSelectors.selectQuestions,
  questions: questionsSelectors.selectList,
});

export default R.pipe(
  connect(
    mapStateToProps,
    {
      ...actions,
      getQuestions: questionsActions.getQuestions,
    }),
  withRouter,
)(AddHeadLineFormContainer);
