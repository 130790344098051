import { useState, useEffect, useCallback } from 'react';

const useLoading = (fn, deps) => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState();

  const callFn = useCallback(async () => {
    try {
      setLoading(true);
      const data = await fn();
      setData(data);
    } finally {
      setLoading(false);
    }
  }, [fn]);

  useEffect(() => {
    callFn();
  }, [callFn, deps]);

  return [loading, callFn, data, setData];
};

export default useLoading;
