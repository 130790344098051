import { useState } from 'react';

const useModal = (initialState) => {
  const [isShow, setModal] = useState(initialState);
  const openModal = () => setModal(true);
  const hideModal = () => setModal(false);

  return [isShow, openModal, hideModal, setModal];
};

export default useModal;
