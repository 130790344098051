import * as R from 'ramda';
import axios from 'axios';
import { API_ENDPOINT } from '../config';

const axiosConfig = {
  baseURL: API_ENDPOINT,
  // timeout: 10000,
};

export const apiInstance = axios.create(axiosConfig);

export const getQuestions = async () => {
  const { data } = await apiInstance.get('/headlines/questions/list');
  return data;
};

export const addQuestion = async (body) => {
  const { data } = await apiInstance.post('/headlines/questions/add', body);
  return data;
};

export const deleteQuestion = async (id) => {
  const { data } = await apiInstance.post(`/headlines/questions/delete/${id}`);
  return data;
};

export const getCategories = async () => {
  const { data } = await apiInstance.get('/headlines/category/list');
  return data;
};

export const addCategory = async (body) => {
  const { data } = await apiInstance.post('/headlines/category/add', body);
  return data;
};

export const deleteCategory = async (id) => {
  const { data } = await apiInstance.post(`/headlines/category/delete/${id}`);
  return data;
};

export const getHeadlines = async () => {
  const { data } = await apiInstance.get('/headlines/list');
  return data;
};

export const addHeadline = async (body) => {
  const { data } = await apiInstance.post('/headlines/add', body);
  return data;
};

export const deleteHeadline = async (id) => {
  const { data } = await apiInstance.post(`/headlines/delete/${id}`);
  return data;
};

export const getSequences = async (params) => {
  const { data } = await apiInstance.get('/script_templates/list/email_sequence', { params });
  return data;
};

export const deleteSequence = async (id) => {
  const { data } = await apiInstance.post(`/script_templates/delete/${id}`);
  return data;
};

export const addSequence = async (body) => {
  const { data } = await apiInstance.post('/script_templates/add/email_sequence', body);
  return data;
};

export const editSequence = async (body) => {
  const { data } = await apiInstance.post('/script_templates/add/email_sequence', body);
  return data;
};

export const addEmail = async (id, body) => {
  const email = R.dissoc('id', body);
  const { data } = await apiInstance.post(`/script_templates/${id}/add_script/email`, email);
  return data;
};

export const getEmail = async (sequenceId, id) => {
  const { data } = await apiInstance.get(`/script_templates/${sequenceId}/get_script/${id}`);
  return data;
};

export const editEmail = async (sequenceId, id, body) => {
  const { data } = await apiInstance.post(
    `/script_templates/${sequenceId}/add_script/email`,
    R.assoc('id', id, body),
  );
  return data;
};

const deleteEmail = async (sequenceId, id) => {
  const { data } = await apiInstance.get(`/script_templates/${sequenceId}/delete_script/${id}`);
  return data;
};

const uploadEmailImage = async (form) => {
  const { data } = await apiInstance.post(`/script_templates/upload_image`, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return data;
};

export const getWebinars = async (params) => {
  const { data } = await apiInstance.get('/script_templates/list/webinar', { params });
  return data;
};

export const deleteWebinar = async (id) => {
  const { data } = await apiInstance.post(`/script_templates/delete/${id}`);
  return data;
};

export const addWebinar = async (body) => {
  const { data } = await apiInstance.post('/script_templates/add/webinar', body);
  return data;
};

export const editWebinar = async (body) => {
  const { data } = await apiInstance.post('/script_templates/add/webinar', body);
  return data;
};

export const addSlide = async (id, body) => {
  const slide = R.dissoc('id', body);
  const { data } = await apiInstance.post(`/script_templates/${id}/add_script/slide`, slide);
  return data;
};

export const getSlide = async (sequenceId, id) => {
  const { data } = await apiInstance.get(`/script_templates/${sequenceId}/get_script/${id}`);
  return data;
};

export const editSlide = async (id, slideId, body) => {
  const { data } = await apiInstance.post(
    `/script_templates/${id}/add_script/slide`,
    R.assoc('id', slideId, body),
  );
  return data;
};

const deleteSlide = async (id, slideId) => {
  const { data } = await apiInstance.get(`/script_templates/${id}/delete_script/${slideId}`);
  return data;
};



export const getVSLs = async (params) => {
  const { data } = await apiInstance.get('/script_templates/list/vsl', { params });
  return data;
};

export const addVSL = async (body) => {
  const { data } = await apiInstance.post('/script_templates/add/vsl', body);
  return data;
};

export const deleteVSL = async (id) => {
  const { data } = await apiInstance.post(`/script_templates/delete/${id}`);
  return data;
};

export const editVSL = async (body) => {
  const { data } = await apiInstance.post('/script_templates/add/vsl', body);
  return data;
};

export const getPresentation = async (id) => {
  const response = await apiInstance.get(`/script_templates/export_as_pptx/${id}`);
  return R.path(['data', 'image_url'], response);
};

export default {
  getQuestions,
  addQuestion,
  deleteQuestion,
  getCategories,
  addCategory,
  deleteCategory,
  getHeadlines,
  addHeadline,
  deleteHeadline,
  getSequences,
  deleteSequence,
  addSequence,
  addEmail,
  getEmail,
  editEmail,
  deleteEmail,
  editSequence,
  uploadEmailImage,
  getWebinars,
  addWebinar,
  deleteWebinar,
  addSlide,
  editWebinar,
  getSlide,
  editSlide,
  deleteSlide,
  getVSLs,
  addVSL,
  deleteVSL,
  editVSL,
  getPresentation,
};
