import * as R from 'ramda';
import { createPlaceholder } from '../../AddQuestions/putTextToPosition';

const getAllQuestion = (examples) => {
  if (examples.length === 1) {
    return examples[0];
  } else {
    const result = [];
    const allCasesOfRest = getAllQuestion(examples.slice(1));
    for (let i = 0; i < allCasesOfRest.length; i++) {
      for (let j = 0; j < examples[0].length; j++) {
        result.push([examples[0][j], allCasesOfRest[i]].flat());
      }
    }
    return result;
  }
};

const replacePlaceholder = (str1, placeholder, str2) => str1.replace(createPlaceholder(placeholder), str2);
const matchesToQuestions = R.curry((matches, placeholders, string) => {
  if (!R.is(Array, matches)) {
    return;
  }

  return matches.map(el => {
    if (R.is(Array, el)) {
      return el.reduce((acc, el, idx) => replacePlaceholder(acc, placeholders[idx], el), string);
    }
    
    return replacePlaceholder(string, placeholders[0], el)
  });
});

export const getPlaceholders = value => {
  if (!R.is(String, value)) {
    return [];
  }

  let arr;
  const finalArr = [];
  const regex = /\[\[\[([^\[\]]+)\]\]\]/gi;

  while ((arr = regex.exec(value)) !== null) {
    finalArr.push(arr[1])
  }

  return finalArr;
};
const getExamples = (placeholders, questions, count) => {
  return R.addIndex(R.map)((el, idx) => {
    const examples = R.pathOr('', [el, 'examples'], questions);

    if (R.isNil(count)) {
      return examples;
    }

    return R.take(count, examples);
  })(placeholders)
};
export default (questions, string, count) => {
  const placeholders = getPlaceholders(string);

  if (R.isEmpty(placeholders)) {
    return [string];
  }
 
  return R.pipe(
    getExamples,
    getAllQuestion,
    matchesToQuestions(R.__, placeholders, string)
  )(placeholders, questions, count)
};
