import * as R from 'ramda';
import {
  GET_QUESTIONS_REQUEST, GET_QUESTIONS_SUCCESS,
  ADD_QUESTION_SUCCESS,
} from './constants';

export const initialState = {
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS_REQUEST:
      return state;

    case GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        result: action.result,
        list: action.list,
      };
    
    case ADD_QUESTION_SUCCESS:
      return {
        ...state,
        result: R.append(action.item.id, state.result),
        list: R.assoc(action.item.id, action.item, state.list),
      }
    
    default:
      return state;
  }
};

const name = 'QUESTIONS';

export default {
  name,
  reducer,
};
