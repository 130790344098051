import React, { useState, useRef, useMemo, useEffect } from 'react';
import * as R from 'ramda';
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/legacyoutput';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import { Editor } from '@tinymce/tinymce-react';
import { connect } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { getPlaceholders } from '../../Headlines/AddHeadlineForm/getAllResults';
import useCaret from '../../../hooks/useCaret';
import AddQuestions from '../../AddQuestions';
import putTextToPosition, { createPlaceholder } from '../../AddQuestions/putTextToPosition';
import './EmailInput.scss';

const initEditor = {
  height: 'auto',
  // max_height: 200,
  menubar: false,
  plugins: ['paste', 'link', 'lists', 'legacyoutput', 'image', 'directionality'],
  skin_url: `${process.env.PUBLIC_URL}/skins/ui/oxide`,
  directionality: 'rtl',
  inline_styles : true,
  toolbar: [
    'undo redo | lists | formatselect | bold italic forecolor backcolor link | alignleft aligncenter alignright alignjustify',
    'bullist numlist outdent indent | removeformat | help | image | ltr rtl'
  ],
  setup (e) {
    e.on('init', function() {
      e.contentAreaContainer.querySelector('iframe').setAttribute('scrolling', 'no')
    });
  }
};

const CARET = 'caret';
const PUT_TO = 'putTo';

const subjectName = 'subject';
const shortName = 'description';
const bodyName = 'body';

const bySubjectName = R.propOr('', subjectName);
const byShortName = R.propOr('', shortName);
const byBodyName = R.propOr('', bodyName);

const addPlaceholderTo = (input, value, formik) => {
  let originalString = input.byName(formik.values);
  const { finalString, newCaretPosition } = putTextToPosition(input.caret.caretPosition, originalString)(value);

  formik.setFieldValue(input.name, R.trim(finalString));
  input.caret.focusAndSetCuretPosition(newCaretPosition);
};

const getInput = (name, inputs) => R.propOr(inputs[subjectName], name, inputs);

const EmailInput = ({
  questionsList,
  onChange,
  name,
  formik,
  showErrors,
  openQuestion,
}) => {
  const editorRef = useRef();
  const subjectCaret = useCaret();
  const shortCaret = useCaret();

  useEffect(() => {
    try {
      formik.validateForm();
    } catch {}
  }, [questionsList]);

  const handleEditorChange = (value) => {
    formik.setFieldValue(bodyName, value);
    if (R.is(Function, onChange)) {
      onChange(value);
    }
  };

  const [currentPlaceholderInput, setCurrentPlaceholderInput] = useState();

  const inputs = {
    [subjectName]: {
      name: subjectName,
      byName: bySubjectName,
      [CARET]: subjectCaret,
      [PUT_TO]: (value) => {
        const input = getInput(currentPlaceholderInput, inputs);
        addPlaceholderTo(input, value, formik);
      },
    },

    [shortName]: {
      name: shortName,
      byName: byShortName,
      [CARET]: shortCaret,
      [PUT_TO]: (value) => {
        const input = getInput(currentPlaceholderInput, inputs);
        addPlaceholderTo(input, value, formik);
      },
    },

    [bodyName]: {
      name: bodyName,
      byName: byBodyName,
      [CARET]: R.identity,
      [PUT_TO]: (value) => {
        editorRef.current.editor.execCommand('mceInsertContent', true, createPlaceholder(value));
      },
    },
  };

  const questionField = getInput(currentPlaceholderInput, inputs);
  const caretPosition = questionField.caretPosition;
  const onAddClickHandler = value => {
    questionField[PUT_TO](value);
  };

  return (
    <div className='add-vsl-slide-form add-vsl-slide-form--vsl'>
      {/* <div className='add-webinar-slide-form__right-inputs'>
        <Form.Group>
          <Form.Control
            type='text'
            placeholder='Slide Title'
            name={subjectName}
            value={bySubjectName(formik.values)}
            onChange={formik.handleChange}
            onFocus={() => setCurrentPlaceholderInput(subjectName)}
            ref={subjectCaret.textAreaRef}
            onClick={subjectCaret.handleCaretPosition}
            onKeyUp={subjectCaret.handleCaretPosition}
            isValid={bySubjectName(formik.touched) && !bySubjectName(formik.errors)}
            isInvalid={showErrors && !!bySubjectName(formik.errors)}
          />
          <Form.Control.Feedback type='invalid'>
            {bySubjectName(formik.errors)}
          </Form.Control.Feedback>
        </Form.Group>

        <AddQuestions
          openQuestion={openQuestion}
          questionsList={questionsList}
          onAddClick={onAddClickHandler}
          caretPosition={caretPosition}
        />
      </div> */}

        <Form.Group>
          <Form.Control
            type='text'
            placeholder='Slide Title'
            name={subjectName}
            value={bySubjectName(formik.values)}
            onChange={formik.handleChange}
            onFocus={() => setCurrentPlaceholderInput(subjectName)}
            ref={subjectCaret.textAreaRef}
            onClick={subjectCaret.handleCaretPosition}
            onKeyUp={subjectCaret.handleCaretPosition}
            isValid={bySubjectName(formik.touched) && !bySubjectName(formik.errors)}
            isInvalid={showErrors && !!bySubjectName(formik.errors)}
          />
          <Form.Control.Feedback type='invalid'>
            {bySubjectName(formik.errors)}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className='add-vsl-slide-form__email-input'>
          <Editor
            ref={editorRef}
            name={bodyName}
            onFocus={() => setCurrentPlaceholderInput(bodyName)}
            value={byBodyName(formik.values)}
            init={initEditor}
            onEditorChange={handleEditorChange}
          />
          <Form.Control
            type='hidden'
            isValid={byBodyName(formik.touched) && !byBodyName(formik.errors)}
            isInvalid={showErrors && !!byBodyName(formik.errors)}
          />
          <Form.Control.Feedback type='invalid'>
            {byBodyName(formik.errors)}
          </Form.Control.Feedback>
        </Form.Group>
    </div>
  );
};

export default connect(EmailInput);
