import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

const DeleteButton = ({
  id,
  onDelete
}) => {
  const [isProcess, setIsProcess] = useState(false);

  const onDeleteHandler = async () => {
    try {
      setIsProcess(true);
      await onDelete(id)
    } finally {
      setIsProcess(false)
    }
  };

  return (
    <Button disabled={isProcess} variant='danger' onClick={onDeleteHandler}>
      {isProcess ? (
        <Spinner
          as='span'
          animation='border'
          size='sm'
          role='status'
          aria-hidden='true'
        />
      ) : (
        <i className='fas fa-trash-alt' />
      )}
    </Button>
  );
};

export default DeleteButton;
