import React, { useEffect } from 'react';
import { AddForm } from '../components/Emails';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as R from 'ramda';
import applySelectors from '../utils/applySelectors';
import questionsSelectors from '../store/questions/selectors';
import questionsActions from '../store/questions/actions';
import actions from '../store/emails/actions';
import { Spinner } from 'react-bootstrap';
import AddQuestionModalContainer from './AddQuestionModalContainer';
import useModal from '../hooks/useModal';

const AddEmailContainer = ({
  questionsList,
  questions,
  getQuestions,
  addSequence,
  uploadEmailImage,
  history,
  setSequencePage,
}) => {
  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const [isQuestionModal, openQuestionModal, hideQuestionModal] = useModal();

  if (!questions) {
    return <Spinner />;
  }

  const onSubmitHandler = async (...args) => {
    try {
      await addSequence(...args);
      setSequencePage(0);
      history.push('/emails');
    } catch {
    }
  };

  return (
    <>
      <AddQuestionModalContainer show={isQuestionModal} onHide={hideQuestionModal} />
      <AddForm
        onSubmit={onSubmitHandler}
        questionsList={questionsList}
        questions={questions}
        openQuestion={openQuestionModal}
        uploadEmailImage={uploadEmailImage}
      />
    </>
  );
};

const mapStateToProps = applySelectors({
  questionsList: questionsSelectors.selectQuestions,
  questions: questionsSelectors.selectList,
});

export default R.pipe(
  connect(
    mapStateToProps,
    {
      ...actions,
      getQuestions: questionsActions.getQuestions,
    }
  ),
  withRouter,
)(AddEmailContainer);
