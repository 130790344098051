import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import List from '../components/List';
import applySelectors from '../utils/applySelectors';
import actions from '../store/categories/actions';
import selectors from '../store/categories/selectors';
import Head from '../components/Head/Head';
import useLoading from '../hooks/withLoading';
import useErrors from '../hooks/useErrors';
import { Modal, ListGroup } from 'react-bootstrap';
import ErrorsModal from '../components/ErrorsModal';

const CategoriesContainer = ({
  getCategories,
  deleteCategory,
  list,
  messages,
  history
}) => {
  const [loading] = useLoading(getCategories);
  const onDeleteCategory = useErrors(deleteCategory);

  const onAddHandler = () => {
    history.push('/categories/add');
  };

  const onEditHandler = (id) => {
    history.push(`/categories/edit/${id}`);
  };

  return (
    <>
      <Head title='Categories' onAdd={onAddHandler} />
      <List
        list={list}
        onDelete={onDeleteCategory.handler}
        onEdit={onEditHandler}
        loading={loading}
      />
      <ErrorsModal errorsHook={onDeleteCategory} />
    </>
  );
};

const mapStateToProps = applySelectors({
  list: selectors.selectCategories
});

export default R.pipe(
  connect(mapStateToProps, actions),
  withRouter,
)(CategoriesContainer);
