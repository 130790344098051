import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import AddQuestionForm from '../components/Questions/AddQuestionForm';
import applySelectors from '../utils/applySelectors';
import selectors from '../store/questions/selectors';
import actions from '../store/questions/actions';
import Head from '../components/Head/Head';
import { Spinner } from 'react-bootstrap';

const EditQuestionsContainer = ({
  editQuestion,
  getQuestions,
  item,
  history,
}) => {
  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const editQuestionHandler = async (data, formik) => {
    try {
      await editQuestion(data);
      history.push('/questions');
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <>
      <Head title='Edit question' />
      {item ? (
        <AddQuestionForm
          initialValues={item}
          onSubmit={editQuestionHandler}
          edit
        />
      ) : (
        <Spinner animation='border' role='status' />
      )}
    </>
  );
};

const mapStateToProps = applySelectors({
  item: selectors.selectQuestion
});

export default R.pipe(
  connect(mapStateToProps, actions),
  withRouter,
)(EditQuestionsContainer);
