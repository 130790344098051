import React from 'react';
import * as R from 'ramda';
import ListItem from './ListItem';
import { Spinner } from 'react-bootstrap';

const List = ({
  list,
  loading,
  onEdit,
  onDelete,
}) => {
  const loadingChild = (R.isNil(list) || loading) && (
    <Spinner animation='border' role='status' />
  );

  return (
    <>
      {loadingChild}
      {R.is(Array, list) && R.map(item => (
        <ListItem
          key={item.id}
          item={item}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      ), list)}
    </>
  );
};

export default List;
