import React, { useEffect } from 'react'
import { Toast } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as R from 'ramda';
import applySelectors from '../utils/applySelectors';
import selectors from '../store/toasts/selectors';
import actions from '../store/toasts/actions';

const ToastComponent = ({
  id,
  title,
  hideAfter,
  deleteToast,
}) => {
  useEffect(() => {
    let timeout;

    if (hideAfter) {
      timeout = setTimeout(() => {
        deleteToast(id);
      }, hideAfter);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [deleteToast, hideAfter, id]);

  return (
    <Toast onClose={() => deleteToast(id)}>
      <Toast.Header>
        <strong style={{ width: '100%', textAlign: 'initial' }}>{title}</strong>
      </Toast.Header>
    </Toast>
  );
};

const ToastsContainer = ({
  toasts,
  deleteToast,
}) => (
  <div
    aria-live="polite"
    aria-atomic="true"
    style={{
      position: 'fixed',
      width: '300px',
      maxHeight: '100%',
      overflow: 'auto',
      top: '10px',
      right: '10px',
    }}
    >
    {R.map(item => <ToastComponent key={item.id} {...item} deleteToast={deleteToast} />, toasts)}
  </div>
)

const mapStateToProps = applySelectors({
  toasts: selectors.selectToasts
});

export default connect(mapStateToProps, actions)(ToastsContainer);
