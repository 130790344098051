import * as R from 'ramda';
import { normalize } from 'normalizr';
import api from '../../services/api';
import {
  GET_WEBINARS_REQUEST, GET_WEBINARS_SUCCESS,
  DELETE_WEBINAR_REQUEST, DELETE_WEBINAR_SUCCESS,
  EDIT_WEBINAR_REQUEST,
  NEXT_WEBINAR_PAGE, PREV_WEBINAR_PAGE, SET_WEBINAR_PAGE,
} from './constants';
import toastActions from '../toasts/actions';
import schemas from './schemas';

const getWebinarsRequest = () => ({
  type: GET_WEBINARS_REQUEST,
});

const getWebinarsSuccess = ({ result, entities }, paginationData) => ({
  type: GET_WEBINARS_SUCCESS,
  result,
  list: entities,
  pagination: paginationData,
});

const deleteWebinarRequest = () => ({
  type: DELETE_WEBINAR_REQUEST,
});

const deleteWebinarSuccess = (id) => ({
  type: DELETE_WEBINAR_SUCCESS,
  id,
});

const getWebinars = (params) => async dispatch => {
  try {
    dispatch(getWebinarsRequest());
    const data = await api.getWebinars(params);
    const paginationData = R.pick(['cursor', 'has_more', 'count'], data);
    const normalizedData = normalize(data.script_templates, [schemas.slidesSchema]);
    dispatch(getWebinarsSuccess(normalizedData, paginationData))
  } catch {

  }
};

const deleteWebinar = (id) => async dispatch => {
  try {
    dispatch(deleteWebinarRequest());
    await api.deleteWebinar(id);
    dispatch(deleteWebinarSuccess(id))
    dispatch(toastActions.addToast({ title: 'Webinar successfully deleted!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const addSlide = (id, email) => async dispatch => {
  try {
    const { id: emailId } = await api.addSlide(id, email);
    dispatch(toastActions.addToast({ title: 'Slide successfully added!' }));
    return emailId;
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const editSlide = (sequenceId, id, email) => async dispatch => {
  try {
    const { id: slideId } = await api.editSlide(sequenceId, id, email);
    dispatch(toastActions.addToast({ title: 'Slide successfully saved!' }));
    return slideId;
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const deleteSlide = (sequenceId, id) => async dispatch => {
  try {
    await api.deleteSlide(sequenceId, id);
    dispatch(toastActions.addToast({ title: 'Slide successfully deleted!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const addWebinar = (data) => async dispatch => {
  try {
    const sequence = R.dissoc('script_ids', data);
    const { id } = await api.addWebinar(sequence);

    for (let i = 0; i < data.script_ids.length; i++) {
      const slide = R.pipe(
        R.path(['script_ids', i]),
        R.dissoc('id'),
      )(data);
      await api.addSlide(id, slide);
    }
    // dispatch(deleteSequenceSuccess(id))
    dispatch(toastActions.addToast({ title: 'Webinar successfully added!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const editSequenceSuccess = (item) => ({
  type: EDIT_WEBINAR_REQUEST,
  item,
})

const editWebinar = data => async dispatch => {
  try {
    const script_ids = R.pipe(
      R.prop('script_ids'),
      R.map(R.prop('id')),
    )(data);

    const sequence = R.pipe(
      R.pick(['id', 'image', 'description', 'name']),
      R.assoc('script_ids', script_ids),
    )(data);

    const { id } = await api.editWebinar(sequence);
    dispatch(editSequenceSuccess(data))
    dispatch(toastActions.addToast({ title: 'Webinar successfully saved!' }));
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
};

const getSlide = (sequenceId, id) => async dispatch => {
  try {
    return await api.getSlide(sequenceId, id);
  } catch {
  }
};

const uploadImage = (form) => async dispatch => {
  try {
    const { image_url } = await api.uploadEmailImage(form);
    dispatch(toastActions.addToast({ title: 'Image successfully uploaded!' }));
    return image_url;
  } catch {
    dispatch(toastActions.addToast({ title: 'Something went wrong!' }));
  }
}

const nextSequencePageAction = () => ({
  type: NEXT_WEBINAR_PAGE,
});
const nextSequencePage = () => dispatch => {
  dispatch(nextSequencePageAction());
};

const prevSequencePageAction = () => ({
  type: PREV_WEBINAR_PAGE,
});
const prevSequencePage = () => dispatch => {
  dispatch(prevSequencePageAction());
};

const setSequencePageAction = (page) => ({
  type: SET_WEBINAR_PAGE,
  page,
});
const setSequencePage = (page) => dispatch => {
  dispatch(setSequencePageAction(page));
};

const getPresentation = (id) => dispatch => {
  return api.getPresentation(id);
};

export default {
  getWebinars,
  deleteWebinar,
  addWebinar,
  getSlide,
  addSlide,
  editSlide,
  deleteSlide,
  editWebinar,
  uploadImage,
  nextSequencePage,
  prevSequencePage,
  setSequencePage,
  getPresentation,
};
