import React, { useState } from 'react';
import * as R from 'ramda';
import { connect } from 'formik';
import { Form, Spinner, Image } from 'react-bootstrap';

const ImageUpload = ({
  formik,
  onChange,
}) => {
  const [isProcess, setIsProcess] = useState(false);
  const onImageChange = async (e) => {
    try {
      const file = e.currentTarget.files[0];

      if (R.isNil(file)) {
        return;
      }

      setIsProcess(true);

      const form = new FormData();
      form.append('image', file);
      
      const url = await onChange(form);
      formik.setFieldValue('image', url);
    } catch (e) {
      formik.setFieldError('image', 'something went wrong');
    } finally {
      setIsProcess(false);
    }
  };

  const value = formik.values.image;
  const processContainerClass = isProcess ? 'add-emails-form__image-container--loading' : '';

  return (
    <Form.Group className='add-emails-form__image'>
      <Form.Label>Image</Form.Label>
      <div className='add-emails-form__image-group'>
        <div className={`add-emails-form__image-container ${processContainerClass}`}>
          {isProcess && (
            <Spinner
              as='span'
              animation='border'
              size='sm'
              role='status'
              aria-hidden='true'
            />
          )}
          {value && (<Image src={value} thumbnail />)}
        </div>
        <div className='custom-file'>
          <Form.Control
            id='customFile'
            type='file'
            disabled={isProcess}
            className='custom-file-input'
            placeholder='Description'
            onChange={onImageChange}
            accept='image/*'
          />
          <label className='custom-file-label' htmlFor='customFile'></label>
        </div>
      </div>
      <Form.Control
        type='hidden'
        name='image'
        onChange={formik.handleChange}
        isValid={formik.touched.image && !formik.errors.image}
        isInvalid={!!formik.errors.image}
      />
      <Form.Control.Feedback type='invalid'>
        {formik.errors.image}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default connect(ImageUpload);
