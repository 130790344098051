import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import actions from '../store/headlines/actions';
import categoriesActions from '../store/categories/actions';
import AddHeadLineFormContainer from './AddHeadLineFormContainer';
import Head from '../components/Head/Head';

const AddHeadlineContainer = ({
  getCategories,
  addHeadline,
  history,
}) => {
  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const addHeadlineHandler = async (data, formik) => {
    try {
      await addHeadline(data);
      history.push('/headlines');
    } finally {
      formik.setSubmitting(false);
    }
  };

  return (
    <>
      <Head title='Add headline' />
      <AddHeadLineFormContainer onSubmit={addHeadlineHandler} />
    </>
  );
};

export default R.pipe(
  connect(null, {
    ...actions,
    getCategories: categoriesActions.getCategories,
  }),
  withRouter,
)(AddHeadlineContainer);
