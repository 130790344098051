import * as R from 'ramda';
import {
  GET_VSLS_REQUEST, GET_VSLS_SUCCESS,
  DELETE_VSL_SUCCESS,
  EDIT_VSL_REQUEST,
  NEXT_VSL_PAGE, PREV_VSL_PAGE, SET_VSL_PAGE,
} from './constants';

export const initialState = {
  pagination: {
    count: undefined,
    has_more: undefined,
    per_page: 10,
    current_page: 0,
    cursors: [undefined],
  },
};

const createCursors = (newCursor, cursors, page) => {
  const nextPage = page + 1;

  if (R.isNil(cursors[nextPage])) {
    return R.append(newCursor, cursors);
  }

  if (newCursor === cursors[nextPage]) {
    return cursors;
  };

  return cursors.map((item, idx) => idx === nextPage ? newCursor : item);
};
const createPagination = (state, action) => {
  let cursors = state.pagination.cursors;

  if (action.pagination.cursor) {
    cursors = createCursors(action.pagination.cursor, cursors, state.pagination.current_page);
  }

  return R.mergeAll([
    state.pagination,
    action.pagination,
    { cursors },
  ]);
}

const nextPage = (state) => {
  return R.mergeAll([
    state.pagination,
    {
      current_page: state.pagination.current_page + 1,
    },
  ]);
};

const setPage = (state, action) => {
  return R.mergeAll([
    state.pagination,
    {
      current_page: action.page,
    },
  ]);
};

const prevPage = (state) => {
  console.log(R.dropLast(1, state.pagination.cursors))
  return R.mergeAll([
    state.pagination,
    {
      current_page: state.pagination.current_page - 1,
      // cursors: R.dropLast(1, state.pagination.cursors),
    },
  ]);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VSLS_REQUEST:
      return state;
      
    case GET_VSLS_SUCCESS:
      return {
        ...state,
        result: action.result,
        list: action.list,
        pagination: createPagination(state, action),
      };

    case DELETE_VSL_SUCCESS:
      return {
        ...state,
        result: R.filter(R.complement(R.equals)(action.id), state.result),
        list: R.dissocPath(['script_templates', action.id], state.list),
      };
    
    case EDIT_VSL_REQUEST:
      return {
        ...state,
        list: R.assocPath(['script_templates', action.item.id], action.item, state.list),
      };
    
    case NEXT_VSL_PAGE:
      return {
        ...state,
        pagination: nextPage(state, action),
      };
    
    case PREV_VSL_PAGE:
      return {
        ...state,
        pagination: prevPage(state, action),
      };
    
    case SET_VSL_PAGE:
      return {
        ...state,
        pagination: setPage(state, action),
      };

    default:
      return state;
  }
};

const name = 'VSL';

export default {
  name,
  reducer,
};
