import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { ButtonGroup, ListGroup, Spinner } from 'react-bootstrap';
import { EditButton, DeleteButton } from '../Buttons';
import './List.scss';

const List = ({
  list,
  onDelete,
  onEdit,
  loading,
}) => {
  const loadingChild = (R.isNil(list) || loading) && (
    <Spinner animation='border' role='status' />
  );

  return (
    <ListGroup>
      {loadingChild}
      {R.is(Array, list) && R.map(item => (
        <ListGroup.Item key={item.id}>
          <div className='list__item'>
            <span>{item.name}</span>
            <ButtonGroup aria-label="First group">
              {onEdit && <EditButton id={item.id} onClick={onEdit} />}
              {onDelete && <DeleteButton id={item.id} onDelete={onDelete} />}
            </ButtonGroup>
          </div>
        </ListGroup.Item>
      ), list)}
    </ListGroup>
  );
};

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

List.defaultProps = {
  onDelete: undefined,
  onEdit: undefined,
};

export default List;
