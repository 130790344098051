import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import actions from '../store/questions/actions';
import Head from '../components/Head/Head';
import AddQuestionFormContainer from './AddQuestionFormContainer';

const AddQuestionContainer = ({
  history,
}) => {
  const afterSubmitHandler = async (data, formik) => {
    history.push('/questions');
  };

  return (
    <>
      <Head title='Add question' />
      <AddQuestionFormContainer
        afterSubmit={afterSubmitHandler}
      />
    </>
  );
};

export default R.pipe(
  connect(null, actions),
  withRouter,
)(AddQuestionContainer);
