import React, { useMemo, useState, useCallback } from 'react';
import * as yup from 'yup';
import { Formik, FieldArray, yupToFormErrors, connect } from 'formik';
import * as R from 'ramda';
import uuid from 'uuid/v4';
import { Form, Button, ButtonGroup, ListGroup, Spinner } from 'react-bootstrap';
import EmailInput from './EmailInput';
import testResult, { errorMessage } from '../../Headlines/AddHeadlineForm/testResult';
import EmailModal from './EmailModal';
import { ProcessButton } from '../../Buttons';
import './Form.scss';
import { NEW_EMAIL } from './constants';
import EmailList from './EmailList';
import ImageUpload from './ImageUpload';
import { FormikChange } from '../../../hocs/withModalOnChange';
import useModal from '../../../hooks/useModal';
import PresentationPreview from '../../Webinars/AddForm/PresentationPreview';

const defaultEmail = () => ({ id: uuid(), subject: '', description: '', body: '' });

const FormikForm = connect(({
  formik,
  uploadEmailImage,
  setEmailIdx,
  onEmailDeleteHandler,
  emailIdx,
  onLoadEmailHandler,
  deleteEmailIdx,
  onSubmitEmailHandler,
  questionsList,
  questions,
  openQuestion,
  edit,
  isProcess,
  onBack,
  openPreviewModal,
  onDownload,
}) => {
  const {
    handleSubmit,
    isSubmitting,
    submitCount,
    values,
    handleChange,
    touched,
    errors,
  } = formik;

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <div className='add-emails-form__main-row'>
        <div>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Name'
              name='name'
              value={values.name}
              onChange={handleChange}
              isValid={touched.name && !errors.name}
              isInvalid={submitCount && !!errors.name}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              as='textarea'
              placeholder='Description'
              row='8'
              name='description'
              value={values.description}
              onChange={handleChange}
              isValid={touched.description && !errors.description}
              isInvalid={submitCount && !!errors.description}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.description}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
        <div>
          <ImageUpload onChange={uploadEmailImage} />
        </div>
      </div>

      <hr />
      <br />

      <EmailList
        openModal={setEmailIdx}
        onDelete={onEmailDeleteHandler}
      />

      <EmailModal
        show={!R.isNil(emailIdx)}
        id={emailIdx}
        onLoadEmail={onLoadEmailHandler}
        onHide={deleteEmailIdx}
        newEmail={emailIdx !== NEW_EMAIL}
        onSubmit={onSubmitEmailHandler}
        questionsList={questionsList}
        questions={questions}
        openQuestion={openQuestion}
      />

      {!edit && (
        <ProcessButton isProcess={isProcess} variant='primary' type='submit'>Add</ProcessButton>
      )}

      {edit && (
        <ProcessButton isProcess={isProcess} variant='primary' type='submit'>Save</ProcessButton>
      )}

      {onBack && (
        <ProcessButton variant='light' type='button' onClick={onBack}>Back to list</ProcessButton>
      )}

      {edit && (
        <>
          <Button onClick={onDownload} variant='light'>Download presentation</Button>
          <Button onClick={openPreviewModal} variant='light'>Preview</Button>
        </>
      )}
    </Form>
  );
});

const EmailForm = ({
  questionsList,
  questions,
  edit,
  initialValues,
  getEmail,
  addEmail,
  editEmail,
  deleteEmail,
  openQuestion,
  uploadEmailImage,
  onSubmit,
  onBack,
  getPresentation,
  onDownload,
}) => {
  const [isProcess, setIsProcess] = useState(false);
  const [isShowPreview, openPreviewModal, hidePreviewModal] = useModal();

  const schema = useMemo(() => yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    script_ids: yup.array().min(1),
    // image: yup.mixed().required(),
  }), [questions]);

  const [emailIdx, setEmailIdx] = useState();
  const deleteEmailIdx = () => setEmailIdx();

  const onNewSequence = (data, formik) => {
    if (emailIdx === NEW_EMAIL) {
      formik.setFieldValue('script_ids', R.append(data, formik.values.script_ids));
      return;
    }

    formik.setFieldValue('script_ids', formik.values.script_ids.map((item, idx) => {
      if (emailIdx === idx) {
        return data;
      }

      return item;
    }));
  }

  const getShortEmail = (id, data) => ({ id, ...R.pick(['subject'], data), })

  const onEditSequence = async (data, formik) => {
    if (emailIdx === NEW_EMAIL) {
      const id = await addEmail(formik.values.id, data);

      formik.setFieldValue('script_ids', R.append(
        getShortEmail(id, data),
        formik.values.script_ids,
      ));
      return;
    }

    const script = R.path(['script_ids', emailIdx], formik.values);
    const id = await editEmail(formik.values.id, script.id, data);
    formik.setFieldValue('script_ids', formik.values.script_ids.map((item, idx) => {
      if (id === item.id) {
        return getShortEmail(id, data);
      }

      return item;
    }));
  };

  const onSubmitEmailHandler = async (data, formik) => {
    try {
      if (edit) {
        await onEditSequence(data, formik);
      } else {
        onNewSequence(data, formik);
        deleteEmailIdx();
      }
    } catch {}
  };

  const onLoadEmailHandler = async (id, formik) => {
    const script = R.path(['script_ids', id], formik.values);

    if (id === NEW_EMAIL) {
      return defaultEmail();
    }

    if (edit) {
      return await getEmail(formik.values.id, script.id);
    }

    return script;
  };

  const onEmailDeleteHandler = async (item, id, formik) => {
    if (edit) {
      await deleteEmail(formik.values.id, item.id);
    }
  };

  const onSubmitHandler = async (...args) => {
    try {
      setIsProcess(true);
      await onSubmit(...args);
    } finally {
      setIsProcess(false);
    }
  };

  return (
    <Formik
      schema={schema}
      validationSchema={schema}
      onSubmit={onSubmitHandler}
      initialValues={initialValues}
      enableReinitialize
    >
      <>
        <FormikChange onHide={onBack} onAction={onSubmitHandler}>
          {({ onHide }) => (
            <FormikForm
              uploadEmailImage={uploadEmailImage}
              setEmailIdx={setEmailIdx}
              onEmailDeleteHandler={onEmailDeleteHandler}
              emailIdx={emailIdx}
              onLoadEmailHandler={onLoadEmailHandler}
              deleteEmailIdx={deleteEmailIdx}
              onSubmitEmailHandler={onSubmitEmailHandler}
              questionsList={questionsList}
              questions={questions}
              openQuestion={openQuestion}
              edit={edit}
              isProcess={isProcess}
              onBack={edit && onHide}
              openPreviewModal={openPreviewModal}
              onDownload={onDownload}
            />
          )}
        </FormikChange>
        <PresentationPreview
          id={initialValues.id}
          show={isShowPreview}
          onHide={hidePreviewModal}
          getPresentation={getPresentation}
        />
      </>
    </Formik>
  );
};

EmailForm.defaultProps = {
  initialValues: {
    script_ids: [],
  }
};

export default EmailForm;
