import React, { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Button, ListGroup } from 'react-bootstrap';
import Select from 'react-select'
import getAllResults from './getAllResults';
import './AddHeadlineForm.scss';
import AddQuestions from '../../AddQuestions';
import testResult, { errorMessage } from './testResult';
import useCaret from '../../../hooks/useCaret';

const AddHeadlineForm = ({
  edit,
  initialValues,
  categoriesList,
  questionsList,
  questions,
  onSubmit,
  openCategory,
  openQuestion,
  children,
}) => {
  const caret = useCaret();

  const [combinations, setCombinations] = useState();
  const [isShowCombinations, setShowCombinations] = useState(false);
  const toggleCombinations = () => setShowCombinations(!isShowCombinations);
  const updateCombinations = useCallback((str) => {
    setCombinations(getAllResults(questions, str));
  }, [questions]);
  useEffect(() => {
    updateCombinations(initialValues.result)
  }, [updateCombinations, initialValues, questions]);

  const schema = useMemo(() => yup.object({
    title: yup.string().required(),
    categories: yup.array().of(yup.string().required()).required(),
    result: yup.mixed().required().test({
      message: errorMessage(questions),
      test: value => testResult(questions, value)[0],
    }),
  }), [questions]);

  return (
    <Formik
      schema={schema}
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {
        ({
          isSubmitting,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          touched,
          errors,
          isValid,
          handleBlur,
          submitCount,
        }) => {
          return (
            <>
              <Form noValidate onSubmit={handleSubmit}>

                <Form.Group controlId='title'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter title'
                    name='title'
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.title && !errors.title}
                    isInvalid={submitCount && !!errors.title}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='categories'>
                  <Form.Label>Select categories</Form.Label>
                  <div className='headline-form__categories'>
                    <Select
                      name='categories'
                      options={R.is(Array, categoriesList) && R.map(item => ({ label: item.name, value: item.id }), categoriesList)}
                      value={values.categories}
                      onChange={(value, { action }) => {
                        setFieldValue(
                          'categories',
                          R.is(Array, value) ? value : [],
                        )
                      }}
                      closeMenuOnSelect={false}
                      isMulti
                    />
                    <Button onClick={openCategory} type='button' variant='link'>Add New Category</Button>
                  </div>
                  <Form.Control
                    type='hidden'
                    isValid={touched.categories && !errors.categories}
                    isInvalid={submitCount && !!errors.categories}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.categories}
                  </Form.Control.Feedback>
                </Form.Group>

                <AddQuestions
                  questionsList={questionsList}
                  openQuestion={openQuestion}
                  focusAndSetCuretPosition={caret.focusAndSetCuretPosition}
                  caretPosition={caret.caretPosition}
                  updateCombinations={updateCombinations}
                />

                <Form.Group controlId='result'>
                  <Form.Control
                    ref={caret.textAreaRef}
                    as='textarea'
                    placeholder='Enter result'
                    name='result'
                    value={values.result}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={caret.handleCaretPosition}
                    onKeyUp={(...args) => {
                      caret.handleCaretPosition(...args);
                      updateCombinations(values.result);
                    }}
                    isValid={touched.result && !errors.result}
                    isInvalid={submitCount && !!errors.result}
                    row='5'
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.result}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Button
                      onClick={toggleCombinations}
                      disabled={errors.result}
                      variant='primary'
                      type='button'
                    >
                      {isShowCombinations ? 'Hide examples' : 'See examples'}
                    </Button>
                  </Form.Group>
                <Form.Group>
                  {isShowCombinations && (
                    <ListGroup>
                      {R.is(Array, combinations) && combinations.map(el => (
                        <ListGroup.Item key={el}><span className='rtl-span'>{el}</span></ListGroup.Item>    
                      ))}
                    </ListGroup>
                  )}
                </Form.Group>

                {!edit && (
                  <Button disabled={isSubmitting} variant='primary' type='submit'>
                    Add
                  </Button>
                )}
                {edit && (
                  <Button disabled={isSubmitting} variant='primary' type='submit'>
                    Save
                  </Button>
                )}
              </Form>
              {children}
            </>
          )
        }
      }
    </Formik>
  );
};

AddHeadlineForm.propTypes = {
  openCategory: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}),
  categoriesList: PropTypes.arrayOf(PropTypes.shape()),
  questionsList: PropTypes.arrayOf(PropTypes.shape()),
  edit: PropTypes.bool,
};

AddHeadlineForm.defaultProps = {
  initialValues: {
    title: '',
    categories: [],
  },
  categoriesList: undefined,
  questionsList: undefined,
  edit: false,
};

export default AddHeadlineForm;
