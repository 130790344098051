import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import AddCategoryForm from '../components/Category/AddCategoryForm';
import actions from '../store/categories/actions';

const AddCategoryFormContainer = ({
  addCategory,
  afterSubmit,
}) => {
  const addCategoryHandler = async (data, formik) => {
    try {
      await addCategory(data);

      if (R.is(Function, afterSubmit)) {
        afterSubmit(data, formik);
      }
    } finally {
      formik.setSubmitting(false);
    }
  };
  return (
    <AddCategoryForm
      onSubmit={addCategoryHandler}
    />
  );
};

export default R.pipe(
  connect(null, actions)
)(AddCategoryFormContainer);
