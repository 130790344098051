import { combineReducers } from 'redux';
import questionsReducer from './store/questions/reducer';
import categoriesReducer from './store/categories/reducer';
import headlinesReducer from './store/headlines/reducer';
import toastsReducer from './store/toasts/reducer';
import emailsReducer from './store/emails/reducer';
import webinarsReducer from './store/webinars/reducer';
import vslReducer from './store/vsl/reducer';

const rootReducer = combineReducers({
  [questionsReducer.name]: questionsReducer.reducer,
  [categoriesReducer.name]: categoriesReducer.reducer,
  [headlinesReducer.name]: headlinesReducer.reducer,
  [toastsReducer.name]: toastsReducer.reducer,
  [emailsReducer.name]: emailsReducer.reducer,
  [webinarsReducer.name]: webinarsReducer.reducer,
  [vslReducer.name]: vslReducer.reducer,
});

export default rootReducer;
