import React from 'react';
import { Card, ButtonGroup, Button } from 'react-bootstrap';
import { EditButton, DeleteButton } from '../../Buttons';
import './ListItem.scss';

const ListItem = ({
  item,
  onEdit,
  onDelete,
}) => (
  <Card className='email-list__card'>
    <Card.Body>
      <div className='email-list__image'>
        <Card.Img variant='right' src={item.image} />
      </div>
      <Card.Text>
        <h2>{item.name}</h2>
        <p>{item.description}</p>
        <ButtonGroup>
          {onEdit && <EditButton id={item.id} onClick={onEdit} />}
          {onDelete && <DeleteButton id={item.id} onDelete={onDelete} />}
        </ButtonGroup>
      </Card.Text>
    </Card.Body>
  </Card>
);

export default ListItem;
