import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as R from 'ramda';
import List from '../components/List';
import applySelectors from '../utils/applySelectors';
import actions from '../store/headlines/actions';
import selectors from '../store/headlines/selectors';
import Head from '../components/Head/Head';
import useLoading from '../hooks/withLoading';

const HeadlinesContainer = ({
  getHeadlines,
  deleteHeadline,
  list,
  history,
}) => {
  const [loading] = useLoading(getHeadlines);

  const onAddHandler = () => {
    history.push('/headlines/add');
  };

  const editHeadline = (id) => {
    history.push(`/headlines/edit/${id}`);
  };

  return (
    <>
      <Head title='Headlines' onAdd={onAddHandler} />
      <List
        list={list}
        onDelete={deleteHeadline}
        onEdit={editHeadline}
        loading={loading}
      />
    </>
  );
};

const mapStateToProps = applySelectors({
  list: selectors.selectHeadlines
});

export default R.pipe(
  connect(mapStateToProps, actions),
  withRouter,
)(HeadlinesContainer);
