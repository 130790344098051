import * as R from 'ramda';
import reducer from './reducer';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import schemas from './schemas';

export const root = R.path([reducer.name]);

export const selectResult = R.pipe(root, R.prop('result'));
export const selectList = R.pipe(root, R.prop('list'));

export const selectCategories = createSelector(
  selectResult,
  selectList,
  (result, list) => denormalize(
    { categories: result },
    { categories: [schemas.categoriesSchema] },
    { categories: list }
  ).categories,
);

const selectRouteId = (state, props) => R.path(['match', 'params', 'id'], props);

export const selectCategory = createSelector(
  selectList,
  selectRouteId,
  (list, id) => {
    if (!list) {
      return;
    }

    const category = R.prop(id, list);

    if (!category) {
      return false;
    }

    return category;
  },
);

export default {
  selectCategories,
  selectCategory,
  selectList,
};
